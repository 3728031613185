<div class="tv_view_main">
  <div class="title_main">
    <a href="javascript:;" class="btn_sec" (click)="goBackToAllTeams()">
      <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.70705 13.3644C7.89452 13.1769 7.99983 12.9226 7.99983 12.6574C7.99983 12.3923 7.89452 12.138 7.70705 11.9504L2.75705 7.00045L7.70705 2.05045C7.8892 1.86185 7.99 1.60924 7.98772 1.34705C7.98544 1.08485 7.88027 0.834038 7.69487 0.648631C7.50946 0.463222 7.25864 0.358055 6.99645 0.355776C6.73425 0.353498 6.48165 0.454292 6.29305 0.636451L0.636048 6.29345C0.448577 6.48097 0.343261 6.73528 0.343261 7.00045C0.343261 7.26561 0.448577 7.51992 0.636048 7.70744L6.29305 13.3644C6.48058 13.5519 6.73488 13.6572 7.00005 13.6572C7.26521 13.6572 7.51952 13.5519 7.70705 13.3644Z"
          fill="#232C89" />
      </svg>
      <span>Back to all Teams</span>
    </a>
    <div class="tv_name">
      <a href="javascript:;" class="res_back" (click)="goBackToAllTeams()">
        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.70705 13.3644C7.89452 13.1769 7.99983 12.9226 7.99983 12.6574C7.99983 12.3923 7.89452 12.138 7.70705 11.9504L2.75705 7.00045L7.70705 2.05045C7.8892 1.86185 7.99 1.60924 7.98772 1.34705C7.98544 1.08485 7.88027 0.834038 7.69487 0.648631C7.50946 0.463222 7.25864 0.358055 6.99645 0.355776C6.73425 0.353498 6.48165 0.454292 6.29305 0.636451L0.636048 6.29345C0.448577 6.48097 0.343261 6.73528 0.343261 7.00045C0.343261 7.26561 0.448577 7.51992 0.636048 7.70744L6.29305 13.3644C6.48058 13.5519 6.73488 13.6572 7.00005 13.6572C7.26521 13.6572 7.51952 13.5519 7.70705 13.3644Z"
            fill="#232C89" />
        </svg>
      </a>
      <div class="img_sec">
        <app-image-preview [imageUrl]="teamData.icon" [class]="'profile-image'" [isVideo]="false"></app-image-preview>
      </div>
      <div class="name_wrp">
        <h5>{{teamData.team_name}}</h5>
      </div>
    </div>
  </div>

  <div>
    <nz-card class="team_main_card">
      <nz-tabset class="teams_tab_main">

        <!--OverView-->
        <nz-tab nzTitle="Overview">
          <div class="tv_grid_main">
            <div class="tv_grid_title">
              <h5>Social Networks</h5>
              <button class="btn_secondary" (click)="addSocialToTeam()"
                      *ngIf="hasPermission('manage_social_profile')">
                <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.5 6.75H7.25V12H5.75V6.75H0.5V5.25H5.75V0H7.25V5.25H12.5V6.75Z" fill="#494ADF" />
                </svg>
                <span>Add Social Network</span>
              </button>
            </div>
            <ng-container *ngIf="teamData.social_accounts?.length != 0 && !isLoadingPosts">
              <div class="tv_grid_users">
                <div class="tv_grid_users_in" *ngFor="let socialAccount of teamData.social_accounts">
                  <div class="img_sec">
                    <app-image-preview [imageUrl]="socialAccount.social_account?.image" [class]="'profile-image'"
                                       [isVideo]="false"></app-image-preview>
                    <div class="social_icon">
                      <app-provider [iconSize]="sharedService.mediumIcon" [provider]="socialAccount.social_account?.provider"></app-provider>
                    </div>
                  </div>
                  <div class="dtl_sec">

                    <h5>{{socialAccount.social_account?.name}}
                      <a class="margin-left-2" *ngIf="socialAccount.social_account?.reconnect==1"
                         nz-tooltip nzTooltipTitle="Need Reconnect Account" href="javascript:;" aria-label="link">
                        <i nz-icon [nzType]="'info-circle'"></i>
                      </a>
                    </h5>

                    <span>{{socialAccount.social_account?.type | titlecase}}</span>
                  </div>
                </div>
              </div>
            </ng-container>
            <div class="res_add_new">
              <button class="btn_secondary" (click)="addSocialToTeam()"
                      *ngIf="hasPermission('manage_social_profile')">
                <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.5 6.75H7.25V12H5.75V6.75H0.5V5.25H5.75V0H7.25V5.25H12.5V6.75Z" fill="#494ADF" />
                </svg>
                <span>Add Social Network</span>
              </button>
            </div>

            <div class="team_member_skeleton" *ngIf="isLoadingPosts">
              <nz-skeleton [nzAvatar]="true" [nzParagraph]="{ rows: 1 }" *ngFor="let myfeed of staticFeeds">
              </nz-skeleton>
            </div>

            <ng-container *ngIf="teamData.social_accounts?.length == 0">
              <app-empty [message]="'No Social Network Found !'"></app-empty>
            </ng-container>
          </div>
          <div class="tv_grid_main">
            <div class="tv_grid_title">
              <h5>Team Members</h5>
              <button class="btn_secondary" (click)="addMemberToTeam()"
                      *ngIf="hasPermission('manage_social_profile')">
                <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.5 6.75H7.25V12H5.75V6.75H0.5V5.25H5.75V0H7.25V5.25H12.5V6.75Z" fill="#494ADF" />
                </svg>
                <span>Add Team Member</span>
              </button>
            </div>
            <ng-container *ngIf="teamData.members?.length != 0 && !isLoadingPosts">
              <div class="tv_grid_users">
                <div class="tv_grid_users_in" *ngFor="let member of teamData.members">
                  <div class="img_sec">
                    <app-image-preview [imageUrl]="member.user?.profile_pic" [class]="'profile-image'"
                                       [isVideo]="false"></app-image-preview>
                  </div>
                  <div class="dtl_sec" nz-tooltip [nzTooltipTitle]= "member.user?.email">
                    <h5>
                      {{member.user?.name}}
                      <span *ngIf="member.status===0">(Pending)</span>
                      <span *ngIf="member.user_id===teamData?.organization?.owner_id">(Super Admin)</span>
                      <span>{{member.user?.email}}</span>
                    </h5>

                  </div>
                </div>
              </div>
            </ng-container>

            <div class="team_member_skeleton" *ngIf="isLoadingPosts">
              <nz-skeleton [nzAvatar]="true" [nzParagraph]="{ rows: 1 }" *ngFor="let myfeed of staticFeeds">
              </nz-skeleton>
            </div>


            <div class="res_add_new">
              <button class="btn_secondary" (click)="addMemberToTeam()"
                      *ngIf="hasPermission('manage_user')">
                <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.5 6.75H7.25V12H5.75V6.75H0.5V5.25H5.75V0H7.25V5.25H12.5V6.75Z" fill="#494ADF" />
                </svg>
                <span>Add Team Member</span>
              </button>
            </div>
            <ng-container *ngIf="teamData.members?.length == 0">
              <app-empty [message]="'No Team Member Found !'"></app-empty>
            </ng-container>
          </div>
          <div class="tv_grid_main">
            <div class="tv_grid_title">
              <h5>People Who can Manage</h5>
            </div>
            <!-- <ng-container *ngIf=" !isLoadingPosts">
              <div class="tv_grid_users">
                <div class="tv_grid_users_in">
                  <div class="img_sec">
                    <app-image-preview [imageUrl]="teamData['organization']?.owner.profile_pic"
                                       [class]="'profile-image'" [isVideo]="false"></app-image-preview>
                  </div>
                  <div class="dtl_sec">
                    <h5>
                      {{teamData['organization']?.owner.name}}
                      <span>(Super Admin)</span>
                    </h5>
                  </div>
                </div>
              </div>
            </ng-container> -->
            <ng-container *ngIf="teamData.members?.length != 0 && !isLoadingPosts">
              <div class="tv_grid_users">
                <ng-container  *ngFor="let member of teamData.members">
                  <ng-container *ngIf="(member.user.permission.includes('manage_user') &&
                  member.user.permission.includes('manage_social_profile')) || member.user_id===teamData?.organization?.owner_id
                  ">
                  <div class="tv_grid_users_in">
                    <div class="img_sec">
                      <app-image-preview [imageUrl]="member.user?.profile_pic" [class]="'profile-image'"
                                         [isVideo]="false"></app-image-preview>
                    </div>
                    <div class="dtl_sec" nz-tooltip [nzTooltipTitle]= "member.user?.email">
                      <h5>
                        {{member.user?.name}}
                        <span *ngIf="member.status===0">(Pending)</span>
                        <span *ngIf="member.user_id===teamData?.organization?.owner_id">(Super Admin)</span>
                        <span>{{member.user?.email}}</span>
                      </h5>
                    </div>
                  </div>
                  </ng-container>
                </ng-container>
              </div>
            </ng-container>
            <div class="team_member_skeleton" *ngIf="isLoadingPosts">
              <nz-skeleton [nzAvatar]="true" [nzParagraph]="{ rows: 1 }" *ngFor="let myfeed of staticFeeds">
              </nz-skeleton>
            </div>
          </div>
        </nz-tab>

        <!--Team Members-->
        <nz-tab nzTitle="Team Members">
          <div>
            <perfect-scrollbar style="max-height: 100%;">
              <nz-table #teamMember class="table_default tm_responsive_table" [nzData]="teamData.members"
                        [nzPageSize]="10" >
                <tbody *ngIf="teamMember.data.length >0 && !isLoadingPosts">
                <tr *ngFor="let member of teamMember.data">
                  <td>
                    <div class="user_account_name">
                      <div class="img_sec">
                        <app-image-preview [imageUrl]="member.user?.profile_pic" [class]="'profile-image'"
                                           [isVideo]="false"></app-image-preview>
                      </div>
                      <div class="name_wrp">
                          <span>{{member.user?.name}}
                            <span *ngIf="member.user_id===teamData?.organization?.owner_id">(Super Admin)</span>
                            <span *ngIf="member.status===0">{{member.user?.email}} (Pending)</span>
                            <span *ngIf="member.status!==0">{{member.user?.email}}</span>
                          </span>
                      </div>
                    </div>
                  </td>

                  <td>
                    <div class="btn_wrap" *ngIf="member.user_id !== user.id && member.user_id !== teamData?.organization?.owner_id">
                      <button class="btn btn_primary" *ngIf="member.status===0 && hasPermission('manage_user')" (click)="resendInvite(member.user_id)"
                              [class.isLoading]="isResending && (member.user_id == resendUserId)">
                        <!-- [disabled]="isResending" -->
                        <svg width="20" height="24" viewBox="0 0 24 24" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_369_698)">
                            <path
                              d="M12.9588 10.9115L8.77835 15.0919L1.81901 10.9284C1.77774 10.9051 1.73909 10.8773 1.70375 10.8457C1.17837 10.3514 1.38626 9.43643 2.10609 9.18329L21.5063 2.36395L14.6869 21.7641C14.4373 22.4762 13.5393 22.6869 13.0415 22.1834C12.9982 22.1373 12.9618 22.0851 12.9333 22.0286L10.5461 17.5667"
                              stroke="#494ADF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_369_698">
                              <rect width="24" height="24" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                        <span>Resend</span>
                        <ng-container *ngIf="isResending && resendUserId===member.user_id">
                          <div class="btn_loader">
                            <i nz-icon nzType="loading"></i>
                          </div>
                        </ng-container>
                      </button>
                      <button class="btn edit-account btn_primary" *ngIf="hasPermission('manage_user')"
                              (click)="editPermission(member.user)"
                              [class.isLoading]="isEditPermission && (member.user_id == selectedUser.id)">
                        <svg width="19" height="22" viewBox="0 0 22 22" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M15.1163 4.13023L17.8698 6.88238L15.1163 4.13023ZM16.887 1.70569L9.44173 9.15092C9.05703 9.53507 8.79467 10.0245 8.68771 10.5575L8 14L11.4425 13.311C11.9755 13.2044 12.4643 12.9431 12.8491 12.5583L20.2943 5.11305C20.518 4.88932 20.6955 4.62371 20.8166 4.33139C20.9377 4.03907 21 3.72577 21 3.40937C21 3.09296 20.9377 2.77966 20.8166 2.48734C20.6955 2.19502 20.518 1.92942 20.2943 1.70569C20.0706 1.48196 19.805 1.30448 19.5127 1.1834C19.2203 1.06232 18.907 1 18.5906 1C18.2742 1 17.9609 1.06232 17.6686 1.1834C17.3763 1.30448 17.1107 1.48196 16.887 1.70569V1.70569Z"
                            stroke="#F5F6F2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          <path
                            d="M17.7652 15.4119V18.7649C17.7652 19.3578 17.5296 19.9263 17.1104 20.3455C16.6912 20.7648 16.1227 21.0003 15.5298 21.0003H3.23535C2.6425 21.0003 2.07393 20.7648 1.65472 20.3455C1.23551 19.9263 1 19.3578 1 18.7649V6.47046C1 5.87761 1.23551 5.30904 1.65472 4.88983C2.07393 4.47062 2.6425 4.23511 3.23535 4.23511H6.58838"
                            stroke="#F5F6F2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <span>Edit Permissions</span>
                        <ng-container *ngIf=" isEditPermission && (member.user_id == selectedUser.id)">
                          <div class="btn_loader">
                            <i nz-icon nzType="loading"></i>
                          </div>
                        </ng-container>
                      </button>
                      <button class="btn delete-account btn_primary" (click)="deleteUser(member.user_id)"
                              [disabled]="isDeleting && deleteUserId===member.user_id"
                              *ngIf="hasPermission('manage_social_profile')">
                        <svg width="19" height="19" viewBox="0 0 19 19" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M6 4L6.544 2.368C6.67664 1.96975 6.93123 1.62333 7.27169 1.37781C7.61216 1.13229 8.02124 1.00011 8.441 1H10.558C10.9779 0.999899 11.3872 1.13198 11.7279 1.37752C12.0686 1.62305 12.3233 1.96959 12.456 2.368L13 4M3.5 4L4.434 17.071C4.45194 17.323 4.56462 17.5589 4.74939 17.7313C4.93417 17.9036 5.17733 17.9996 5.43 18H13.568C13.8208 17.9999 14.0643 17.904 14.2492 17.7316C14.4342 17.5592 14.547 17.3232 14.565 17.071L15.5 4H3.5ZM9.5 9V13V9ZM17.5 4H1.5H17.5Z"
                            stroke="#EB3343" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <span>Delete Member</span>
                        <ng-container *ngIf="isDeleting && deleteUserId===member.user_id">
                          <!-- <span class="m-l-7 sn_rn_loder" nz-icon  nzType="loading"></span> -->
                          <div class="btn_loader">
                            <i nz-icon nzType="loading"></i>
                          </div>
                        </ng-container>
                      </button>
                    </div>
                  </td>
                </tr>
                </tbody>
                <ng-container class="" *ngIf="isLoadingPosts">
                  <nz-skeleton [nzAvatar]="true" [nzParagraph]="{ rows: 1 }" *ngFor="let myfeed of staticFeeds">
                  </nz-skeleton>
                </ng-container>
              </nz-table>

              <div *ngIf="!isLoadingPosts && teamData?.members?.length === 0">
                <app-empty [message]="'No Team Member Found !'"></app-empty>
              </div>
            </perfect-scrollbar>
          </div>
          <div class="btn_custom_add">
            <button class="btn_primary" (click)="addMemberToTeam()"
                    *ngIf="hasPermission('manage_user')">
              <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M17.0208 14.379H14.7292V12.0785C14.7292 11.9519 14.6261 11.8484 14.5 11.8484H13.125C12.999 11.8484 12.8959 11.9519 12.8959 12.0785V14.379H10.6042C10.4782 14.379 10.375 14.4825 10.375 14.609V15.9894C10.375 16.1159 10.4782 16.2194 10.6042 16.2194H12.8959V18.5199C12.8959 18.6465 12.999 18.75 13.125 18.75H14.5C14.6261 18.75 14.7292 18.6465 14.7292 18.5199V16.2194H17.0208C17.1469 16.2194 17.25 16.1159 17.25 15.9894V14.609C17.25 14.4825 17.1469 14.379 17.0208 14.379ZM15.0729 5.752C15.0729 2.60603 12.555 0.049561 9.43258 0.000674736C6.25864 -0.0482115 3.61464 2.56577 3.61464 5.752C3.61464 7.55792 4.44537 9.16829 5.74015 10.2237C4.81979 10.6519 3.98194 11.2399 3.26516 11.9606C1.69538 13.5335 0.807363 15.6098 0.750072 17.824C0.749307 17.8547 0.75467 17.8853 0.765844 17.9138C0.777018 17.9424 0.793776 17.9685 0.815133 17.9904C0.83649 18.0124 0.862012 18.0299 0.890196 18.0418C0.91838 18.0537 0.948655 18.0599 0.979237 18.0598H2.5834C2.70657 18.0598 2.8097 17.9621 2.81256 17.8384C2.86699 16.1705 3.54016 14.609 4.72323 13.4243C5.95786 12.1849 7.5964 11.5033 9.34378 11.5033C12.5063 11.5033 15.0729 8.92961 15.0729 5.752ZM11.9362 8.35448C11.243 9.05039 10.3235 9.43285 9.34378 9.43285C8.3641 9.43285 7.44457 9.05039 6.75135 8.35448C6.40541 8.00902 6.13209 7.59736 5.94757 7.1439C5.76304 6.69045 5.67108 6.20442 5.67713 5.71462C5.68573 4.7714 6.06099 3.85982 6.71697 3.18403C7.40447 2.47662 8.324 2.08265 9.30654 2.07115C10.2776 2.06253 11.2201 2.44211 11.9133 3.12365C12.6237 3.82243 13.0133 4.75702 13.0133 5.752C13.0104 6.73548 12.6294 7.65857 11.9362 8.35448Z"
                  fill="white" />
              </svg>
              <span>Add Team Member</span>
            </button>
          </div>
        </nz-tab>

        <!--Social Networks-->
        <nz-tab nzTitle="Social Networks">
          <div>
            <perfect-scrollbar style="max-height: 100%;">
              <nz-table #socialNetworks [nzData]="teamData.social_accounts" class="table_default tm_responsive_table"
                        [nzPageSize]="10">
                <tbody *ngIf="socialNetworks.data.length > 0 && !isLoadingPosts">
                <tr *ngFor="let socialAccount of socialNetworks.data">
                  <td>
                    <div class="user_account_name">
                      <div class="img_sec">
                        <app-image-preview [imageUrl]="socialAccount.social_account?.image" [class]="'profile-image'"
                                           [isVideo]="false"></app-image-preview>
                        <div class="social_icon">
                          <app-provider [iconSize]="sharedService.mediumIcon" [provider]="socialAccount.social_account?.provider"></app-provider>
                        </div>
                      </div>
                      <div class="name_wrp">

                        <span>{{socialAccount.social_account?.name}}
                          <a class="margin-left-2" *ngIf="socialAccount.social_account?.reconnect==1"
                             nz-tooltip nzTooltipTitle="Need Reconnect Account">
                          <i nz-icon [nzType]="'info-circle'"></i>
                        </a>
                        </span>
                        <span class="plat_form_name">{{socialAccount.social_account?.type | titlecase}}</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="btn_wrap">
                      <button class="btn delete-account btn_primary"
                              (click)="deleteSocialAccount(socialAccount.social_connection_id)"
                              [disabled]="isDeleting && deleteSocialId===socialAccount.social_connection_id"
                              *ngIf="hasPermission('manage_social_profile')"
                              >
                        <svg width="19" height="19" viewBox="0 0 19 19" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M6 4L6.544 2.368C6.67664 1.96975 6.93123 1.62333 7.27169 1.37781C7.61216 1.13229 8.02124 1.00011 8.441 1H10.558C10.9779 0.999899 11.3872 1.13198 11.7279 1.37752C12.0686 1.62305 12.3233 1.96959 12.456 2.368L13 4M3.5 4L4.434 17.071C4.45194 17.323 4.56462 17.5589 4.74939 17.7313C4.93417 17.9036 5.17733 17.9996 5.43 18H13.568C13.8208 17.9999 14.0643 17.904 14.2492 17.7316C14.4342 17.5592 14.547 17.3232 14.565 17.071L15.5 4H3.5ZM9.5 9V13V9ZM17.5 4H1.5H17.5Z"
                            stroke="#EB3343" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <span>Remove Network</span>
                        <ng-container *ngIf="isDeleting && deleteSocialId===socialAccount.social_connection_id">
                          <span class="m-l-7 sn_rn_loder" nz-icon nzType="loading"></span>
                        </ng-container>
                      </button>
                    </div>
                  </td>
                </tr>
                </tbody>
                <div class="" *ngIf="isLoadingPosts">
                  <nz-skeleton [nzAvatar]="true" [nzParagraph]="{ rows: 1 }" *ngFor="let myfeed of staticFeeds">
                  </nz-skeleton>
                </div>
              </nz-table>

              <div *ngIf="!isLoadingPosts && socialNetworks.data.length === 0">
                <app-empty [message]="'No Social Network Found !'"></app-empty>
              </div>
            </perfect-scrollbar>
          </div>
          <div class="btn_custom_add">
            <button class="btn_primary" (click)="addSocialToTeam()"
                    *ngIf="hasPermission('manage_social_profile')">
              <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M17.0208 14.379H14.7292V12.0785C14.7292 11.9519 14.6261 11.8484 14.5 11.8484H13.125C12.999 11.8484 12.8959 11.9519 12.8959 12.0785V14.379H10.6042C10.4782 14.379 10.375 14.4825 10.375 14.609V15.9894C10.375 16.1159 10.4782 16.2194 10.6042 16.2194H12.8959V18.5199C12.8959 18.6465 12.999 18.75 13.125 18.75H14.5C14.6261 18.75 14.7292 18.6465 14.7292 18.5199V16.2194H17.0208C17.1469 16.2194 17.25 16.1159 17.25 15.9894V14.609C17.25 14.4825 17.1469 14.379 17.0208 14.379ZM15.0729 5.752C15.0729 2.60603 12.555 0.049561 9.43258 0.000674736C6.25864 -0.0482115 3.61464 2.56577 3.61464 5.752C3.61464 7.55792 4.44537 9.16829 5.74015 10.2237C4.81979 10.6519 3.98194 11.2399 3.26516 11.9606C1.69538 13.5335 0.807363 15.6098 0.750072 17.824C0.749307 17.8547 0.75467 17.8853 0.765844 17.9138C0.777018 17.9424 0.793776 17.9685 0.815133 17.9904C0.83649 18.0124 0.862012 18.0299 0.890196 18.0418C0.91838 18.0537 0.948655 18.0599 0.979237 18.0598H2.5834C2.70657 18.0598 2.8097 17.9621 2.81256 17.8384C2.86699 16.1705 3.54016 14.609 4.72323 13.4243C5.95786 12.1849 7.5964 11.5033 9.34378 11.5033C12.5063 11.5033 15.0729 8.92961 15.0729 5.752ZM11.9362 8.35448C11.243 9.05039 10.3235 9.43285 9.34378 9.43285C8.3641 9.43285 7.44457 9.05039 6.75135 8.35448C6.40541 8.00902 6.13209 7.59736 5.94757 7.1439C5.76304 6.69045 5.67108 6.20442 5.67713 5.71462C5.68573 4.7714 6.06099 3.85982 6.71697 3.18403C7.40447 2.47662 8.324 2.08265 9.30654 2.07115C10.2776 2.06253 11.2201 2.44211 11.9133 3.12365C12.6237 3.82243 13.0133 4.75702 13.0133 5.752C13.0104 6.73548 12.6294 7.65857 11.9362 8.35448Z"
                  fill="white" />
              </svg>
              <span>Add Social Network</span>
            </button>
          </div>
        </nz-tab>

        <!--Profile Permissions-->
        <nz-tab nzTitle="Permissions" *ngIf="!user?.is_super_admin">
          <div class="team_membr_list_main">
            <h5>Social Profiles with permissions</h5>
            <!--<div class="social_profile_list">
              <perfect-scrollbar style="max-height: 100%;">
                <nz-table #profilesWithPermissions [nzData]="userSocialProfiles" class="table_default tbl_sp_permision"
                  [nzFrontPagination]="false">
                  <thead>
                    <tr>
                      <th>Social Profile</th>
                      <th class="text-right">Profile Permissions</th>
                    </tr>
                  </thead>
                  <tbody *ngIf="profilesWithPermissions.data.length > 0">
                    <tr *ngFor="let account of profilesWithPermissions.data">
                      <td>
                        <div class="tm_first_member">
                          <div class="tm_first_img">
                            <app-image-preview [imageUrl]="account?.social_account?.image"
                              [isVideo]="false"></app-image-preview>
                            <app-provider [provider]="account?.social_account?.provider"></app-provider>
                          </div>
                          <div class="tm_first_label">
                            <span>{{account?.social_account?.name}}</span>
                          </div>
                        </div>
                      </td>
                      <td class="td_text_right">
                        <div>
                          <nz-select nzDropdownClassName="rp_option_main" nzPlaceHolder="Select Roles & Permissions"
                            [nzCustomTemplate]="multipleTemplateRolesPermissions" [(ngModel)]="account.access_type"
                            [nzDisabled]="true">
                            &lt;!&ndash;[nzDisabled]="!sharedService.can('manage_user')"&ndash;&gt;
                            <nz-option *ngFor="let accessType of sharedService.socialAccessTypes; let i=index;"
                              nzCustomContent [nzValue]="i">
                              <div class="rp_main">
                                <h5>{{ accessType }}</h5>
                                <span>{{ sharedService.socialAccessTypesDescription[i] }}</span>
                              </div>
                            </nz-option>
                          </nz-select>
                          <ng-template #multipleTemplateRolesPermissions let-selected>
                            <div class="ant-select-selection-item-content">
                              <span>{{sharedService.socialAccessTypes[selected.nzValue]}}</span>
                            </div>
                          </ng-template>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </nz-table>

                <div *ngIf="userSocialProfiles?.length === 0">
                  <app-empty [message]="'No Profile Permission Found !'"></app-empty>
                </div>
              </perfect-scrollbar>

              &lt;!&ndash;<div class="btn_wrp">
                <button class="btn_primary" (click)="updateUserSocialAccess()"
                        *ngIf="userSocialProfiles?.length > 0"
                        [disabled]="isUpdatingSocialAccess"
                        hasPermission [permission]="'manage_user'">
                  Update Permission
                </button>
              </div>&ndash;&gt;
            </div>-->
            <div class="" *ngIf="isLoadingPosts && isEditPermission">
              <nz-skeleton [nzAvatar]="true" [nzParagraph]="{ rows: 1 }" *ngFor="let myfeed of staticFeeds">
              </nz-skeleton>
            </div>

            <app-member-permission *ngIf="!isEditPermission" [users]="[user]" [team_ids]="team_ids"
                                   [user_permissions]="userPermissions" [userSocialProfiles]="userSocialProfileAccess" [mode]="0"
                                   (permissionChange)="permissionChange($event)"></app-member-permission>

          </div>
        </nz-tab>

      </nz-tabset>
    </nz-card>
  </div>
</div>

<!-- permission modal start -->
<nz-modal [(nzVisible)]="permissionModal" nzClassName="permissionModal" [nzFooter]=null>
  <ng-container *nzModalContent>
    <app-member-permission [users]="[selectedUser]" [team_ids]="team_ids" [user_permissions]="userPermissions"
                           (permissionChange)="permissionChange($event)" [userSocialProfiles]="userSocialProfileAccess" [mode]="2">
    </app-member-permission>

  </ng-container>
</nz-modal>
<!-- permission modal end  -->


<!--Add to Team-->
<nz-modal nzClassName="add_social_form_modal" [(nzVisible)]="addToTeamModal" nzTitle="{{ modalTitle }}" [nzFooter]="null">
  <!-- (nzOnCancel)="closeTeamModal()" (nzOnOk)="saveTeam()" -->
  <ng-container *nzModalContent>
    <a class="close_modal" href="javascript:;" (click)="closeAddToTeamModal()">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.1149 10.0003L18.5649 3.56528C18.8474 3.28282 19.006 2.89973 19.006 2.50028C19.006 2.10083 18.8474 1.71773 18.5649 1.43528C18.2825 1.15282 17.8994 0.994141 17.4999 0.994141C17.1005 0.994141 16.7174 1.15282 16.4349 1.43528L9.99991 7.88528L3.56491 1.43528C3.28245 1.15282 2.89936 0.994141 2.49991 0.994141C2.10046 0.994141 1.71736 1.15282 1.43491 1.43528C1.15245 1.71773 0.993771 2.10083 0.993771 2.50028C0.993771 2.89973 1.15245 3.28282 1.43491 3.56528L7.88491 10.0003L1.43491 16.4353C1.29432 16.5747 1.18272 16.7406 1.10657 16.9234C1.03042 17.1062 0.991211 17.3023 0.991211 17.5003C0.991211 17.6983 1.03042 17.8944 1.10657 18.0771C1.18272 18.2599 1.29432 18.4258 1.43491 18.5653C1.57435 18.7059 1.74025 18.8175 1.92304 18.8936C2.10583 18.9698 2.30189 19.009 2.49991 19.009C2.69793 19.009 2.89398 18.9698 3.07677 18.8936C3.25956 18.8175 3.42546 18.7059 3.56491 18.5653L9.99991 12.1153L16.4349 18.5653C16.5744 18.7059 16.7403 18.8175 16.923 18.8936C17.1058 18.9698 17.3019 19.009 17.4999 19.009C17.6979 19.009 17.894 18.9698 18.0768 18.8936C18.2596 18.8175 18.4255 18.7059 18.5649 18.5653C18.7055 18.4258 18.8171 18.2599 18.8932 18.0771C18.9694 17.8944 19.0086 17.6983 19.0086 17.5003C19.0086 17.3023 18.9694 17.1062 18.8932 16.9234C18.8171 16.7406 18.7055 16.5747 18.5649 16.4353L12.1149 10.0003Z"
          fill="#423FD1" />
      </svg>
    </a>
    <div class="create-team-modal">
      <form nz-form [formGroup]="addToTeamForm">
        <div nz-row>
          <div nz-col nzSpan="24">

            <!--member-->
            <nz-form-item class="team_modal_item" *ngIf="addToTeamModalType==='member'">
              <nz-form-label [nzSpan]="24">Members</nz-form-label>
              <nz-form-control>

                <nz-select (nzOpenChange)="sharedService.openNzSelect($event)" [nzCustomTemplate]="multipleTemplate" nzDropdownClassName="team_social_networks"
                           nzMode="multiple" formControlName="team_members" (ngModelChange)="updateMemberAccount($event)"
                           [nzShowArrow]="true" nzPlaceHolder="Select Members" [class.disabled_while_loading]="isAddingToTeam">
                  <nz-option *ngFor="let team of teamMembersList " nzCustomContent [nzLabel]="team.user.name"
                             [nzValue]="team.user" [nzDisabled]="existMemberIds.includes(team.user_id)">

                    <div class="user_dtl_main">
                      <div class="user_dtl_left">
                        <app-image-preview [imageUrl]="team?.user?.profile_pic" [class]="'profile-image'"
                                           [isVideo]="false"></app-image-preview>
                      </div>
                      <div class="user_dtl_right">
                        <h5>{{ team.user.name }}</h5>
                        <span>{{ team.user.email }}</span>
                      </div>
                    </div>
                  </nz-option>
                </nz-select>
              </nz-form-control>
              <ng-template #multipleTemplate let-selected>
                <div class="ant-select-selection-item-content">
                  <div class="user-wrapper">
                    <div class="user_selected">
                      <app-image-preview [imageUrl]="selected.nzValue?.profile_pic" [class]="'profile-image'"
                      [isVideo]="false"></app-image-preview>
                    </div>
                    <div class="user_name"> {{ selected.nzValue.name }}  </div>
                  </div>
                </div>
              </ng-template>
            </nz-form-item>

            <!--social-->
            <nz-form-item class="team_modal_item" *ngIf="addToTeamModalType==='social'">
              <nz-form-label [nzSpan]="24">Social Networks</nz-form-label>
              <nz-form-control>

                <nz-select (nzOpenChange)="sharedService.openNzSelect($event)" [nzCustomTemplate]="multipleTemplateSocialAccount" nzDropdownClassName="team_social_networks"
                           nzMode="multiple" formControlName="social_networks" (ngModelChange)="updateSocialAccounts($event)"
                           [nzShowArrow]="true" nzPlaceHolder="Select Social Networks" [class.disabled_while_loading]="isAddingToTeam" >
                  <nz-option *ngFor="let account of socialAccounts" nzCustomContent [nzLabel]="account.name"
                             [nzValue]="account" [nzDisabled]="existSocialIds.includes(account.id) || account?.reconnect==1">

                    <div class="user_dtl_main">
                      <div class="user_dtl_left">
                        <app-image-preview [imageUrl]="account.image" [class]="'profile-image'" [isVideo]="false">
                        </app-image-preview>
                        <app-provider [iconSize]="sharedService.mediumIcon" [provider]="account.provider"></app-provider>
                      </div>
                      <div class="user_dtl_right">
                        <h5>{{ account.name }}
                          <a class="margin-left-2" *ngIf="account?.reconnect==1" nz-tooltip nzTooltipTitle="Need Reconnect Account">
                            <i nz-icon [nzType]="'info-circle'"></i>
                          </a>
                        </h5>
                        <span>{{ account.type | titlecase}}</span>
                      </div>
                    </div>
                  </nz-option>
                </nz-select>
              </nz-form-control>
              <ng-template #multipleTemplateSocialAccount let-selected>
                <div class="ant-select-selection-item-content">
                  <div class="user_selected">
                    <app-image-preview [imageUrl]="selected.nzValue.image" [class]="'profile-image'" [isVideo]="false">
                    </app-image-preview>
                    <app-provider [iconSize]="sharedService.mediumIcon" [provider]="selected.nzValue.provider"></app-provider>
                  </div>
                </div>
              </ng-template>
            </nz-form-item>

          </div>
        </div>
      </form>
      <div class="ct_btn_main">
        <button class="btn_primary" nzType="primary" (click)="saveToTeam()"
                [disabled]="isAddingToTeam
                || ((addToTeamModalType==='member' && selectedMembers.length===0) || (addToTeamModalType==='social' && selectedSocialAccounts.length===0))">
          {{ modalTitle }}
          <ng-container *ngIf="isAddingToTeam">
            <span class="m-l-7" nz-icon nzType="loading"></span>
          </ng-container>
        </button>
      </div>
      <!-- <div class="organization_form_action">
        <span>Are you sure you want to make changes?</span>
        <div class="btn_group">
          <button
            class="btn"
            nz-button
            nzType="primary"
            (click)="closeTeamModal()"
            [disabled]="isLoadingTeam || loading"
          >
            Cancel
          </button>
          <button
            class="btn"
            nz-button
            nzType="primary"
            (click)="saveTeam()"
            [disabled]="isLoadingTeam || loading"
          >
            {{ modalTitle }}
            <ng-container *ngIf="isLoadingTeam">
              <span nz-icon nzType="loading"></span>
            </ng-container>
          </button>
        </div>
      </div> -->
    </div>
  </ng-container>
</nz-modal>
<!--Add to Team-->
