<div class="campaign_header">
    <div class="campaign_header_left">
      <div class="boost_post_main">
        <app-provider [className]="'social_icon'" [iconSize]="sharedService.mediumIcon" [provider]="socialAccount.provider"></app-provider>
        <span>
          {{ socialAccount.provider | titlecase }} Boost
        </span>
      </div>
    </div>
    <div class="campaign_header_right">
  
  
      <div class="campaign_info">
        <div class="campaign_info_left">
          <app-image-preview [isVideo]="false" [imageUrl]="socialAccount.image" [class]="'account-list-image'"
            *ngIf="socialAccount.image !== null"></app-image-preview>
          <app-provider [className]="'social_icon'" [iconSize]="sharedService.mediumIcon" [provider]="socialAccount.provider"></app-provider>
        </div>
        <div class="campaign_info_right">
          <h5>{{ socialAccount.name }}</h5>
          <p>{{ socialAccount.type | titlecase }}</p>
        </div>
      </div>
      <a href="javascript:;" class="close_icon" (click)="closeModal()">
        <img src="../../../assets/images/close_icon.svg" alt="">
      </a>
    </div>
</div>
  <div class="campaign_wrapper">
    <ng-container>
  
      <ng-container *ngIf="!campaign && !isAdAccountLoading && adAccounts.length === 0">
        <div class="campaign_form">
          <div class="camp_title">
            <h5>Page and Ad Account</h5>
            <p>Now let's get going! Where should this advertisement be promoted?</p>
          </div>
        </div>
        <div class="campaign_page">
          <div class="campaign_page_left">
            <app-image-preview [isVideo]="false" [imageUrl]="socialAccount.image" [class]="'account-list-image'"
              *ngIf="socialAccount.image !== null"></app-image-preview>
            <app-provider [className]="'social_icon'" [iconSize]="sharedService.mediumIcon" [provider]="socialAccount.provider"></app-provider>
          </div>
          <div class="campaign_page_right">
            <h5>{{ socialAccount.name }}</h5>
            <p>{{ socialAccount.type | titlecase }}</p>
          </div>
        </div>
        <div class="over_warning">
          <img src="../../../assets/images/info_icon.svg" alt="">
          <span>You don't have any ad accounts connected to the selected Page.
            Add an ad account to start campaigning.</span>
        </div>
        <div>
          <button class="btn_primary" (click)="addAccount()">Add an ad account</button>
        </div>
      </ng-container>
  
      <ng-container *ngIf="campaign || (isAdAccountLoading || adAccounts.length > 0)">
        <div class="campaign_wrapper_in" [class.bg-blur]="isAdAccountLoading">
          <div class="campaign_wrapper_left">
            <div class="campaign_form">
              <div class="camp_title">
                <h5>Page and Ad Account</h5>
                <p>Now let's get going! Where should this advertisement be promoted?</p>
              </div>
              <form [formGroup]="adForm">
                <div class="campaign_card">
                  <ng-container *ngIf="!campaign">
                    <div class="campaign_card_header">
                      <h5>Ad Account</h5>
                    </div>
                    <div class="campaign_card_body">
                      <div class="form-group">
                        <nz-form-item>
                          <nz-form-control nzErrorTip="Please select ad account!">
                            <nz-select [nzDisabled]="isAdAccountLoading" nzDropdownClassName="default_dropdown_border"
                              formControlName="ad_account_id" [nzPlaceHolder]="'Select Ad Account'"
                              [(ngModel)]="selectedAdAccount" (ngModelChange)="updateSocialAccount($event)"
                              (nzOpenChange)="sharedService.openNzSelect($event)">
                              <nz-option *ngFor="let account of adAccounts"
                                         [nzValue]="account.accountInfo" [nzLabel]="account.accountInfo.name"></nz-option>
                            </nz-select>
                          </nz-form-control>
                        </nz-form-item>
                      </div>
                    </div>
                  </ng-container>
                </div>

                <ng-container *ngIf="selectedAdAccount">
                  <div class="campaign_card">
                    <div class="campaign_card_header" >
                      <h5>Campaign name<span style="margin-left: 7px;" nz-tooltip nzTooltipTitle="Up to 100 characters with spaces applied."
                        nz-icon nzType="info-circle" nzTheme="fill"></span></h5>
                    </div>
                    <div class="campaign_card_body">
                        <div class="form-group ">
                          <nz-form-item>
                            <nz-form-control>
                              <input class="ant-number" nz-input placeholder="Campaign name" type="text" formControlName="campaign_name" />
                              <div *ngIf="adForm.get('campaign_name').hasError('maxlength') && (adForm.get('campaign_name').dirty || adForm.get('campaign_name').touched)">
                                <div class="error-message">Maximum length exceeded (maximum 100 characters).</div>
                              </div>
                              <div *ngIf="adForm.get('campaign_name').hasError('required') && (adForm.get('campaign_name').dirty || adForm.get('campaign_name').touched)">
                                <div class="error-message">Please enter Campaign name.</div>
                              </div>
                            </nz-form-control>
                          </nz-form-item>
                        </div>
                    </div>
                  </div>
                  <div class="campaign_card">
                    <div class="campaign_card_header">
                      <h5>Campaign Objective</h5>
                    </div>
                    <div class="campaign_card_body">
                      <div class="cam_title"  *ngIf="!campaign">
                        <p>What is the objective that best fits your marketing goals?</p>
                      </div>
                      <div class="cam_obj_list">
                        <div class="cam_obj_list_in">
                          <nz-form-item *ngIf="!campaign">
                            <nz-form-control nzErrorTip="Please select campaign objective">
                              <nz-radio-group formControlName="objective" [(ngModel)]="selectedObjective">
                                <label nz-radio nzValue="{{ objective.id }}"
                                  *ngFor="let objective of linkedinAdObjectives">
                                  <div class="cam_obj_main">
                                    <div class="cam_obj_left">
                                      <img src="{{ objective.icon }}" alt="">
                                    </div>
                                    <div class="cam_obj_right">
                                      <h5>{{ objective.title }}</h5>
                                      <span>{{ objective.description }}</span>
                                    </div>
                                  </div>
                                </label>
                              </nz-radio-group>
                            </nz-form-control>
                          </nz-form-item>
                          <ng-container *ngIf="campaign">
                            <ng-container *ngFor="let objective of linkedinAdObjectives">
                              <div class="cam_obj_main">
                                <div class="cam_obj_left">
                                  <img src="{{ objective.icon }}" alt="">
                                </div>
                                <div class="cam_obj_right">
                                  <h5>{{ objective.title }}</h5>
                                  <span>{{ objective.description }}</span>
                                </div>
                              </div>
                            </ng-container>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
  
                  <div class="campaign_card">
                    <div class="campaign_card_header">
                      <h5>Audience</h5>
                    </div>
                    <div class="campaign_card_body">
                      <div class="cam_title">
                        <p>Select the location, age group and gender of you want to reach with your ad.</p>
                      </div>
                      <div class="location_title">
                        <h5>Location</h5>
                        <p>Target people by including or excluding their location</p>
                      </div>
                      <div class="form_input_wrp location_inputs">
                        <div class="form-group">
                          <div class="search_icon">
                            <span nz-icon nzType="search" nzTheme="outline"></span>
                          </div>
                          <nz-form-item>
                            <nz-form-label>
                              <div class="location_label">
                                <img src="../../../assets/images/location-pin.svg" alt="">
                                <span>
                                  Include
                                </span>
                              </div>
                            </nz-form-label>
                            <nz-form-control nzErrorTip="Please select include location">
                              <nz-select class="tag_items_layout" [nzAllowClear]="false" nzDropdownClassName="default_dropdown_border"
                                nzShowSearch nzAllowClear nzServerSearch formControlName="included_countries" nzMode="multiple"
                                [nzCustomTemplate]="cp_country" [nzPlaceHolder]="'Type to add more include location'"
                                [(ngModel)]="selectedIncludedCountries" (ngModelChange)="updateLinkedinEstimatedReach()"
                                (nzOnSearch)="getCountries($event)" (nzOpenChange)="sharedService.openNzSelect($event)">
                                <nz-option *ngFor="let country of countriesListInclude" nzCustomContent [nzValue]="country">
                                  <div class="default_font">
                                    <h5>{{ country.name }}</h5>
                                  </div>
                                </nz-option>
                                <nz-option *ngIf="isCountriesLoading" nzDisabled nzCustomContent>
                                  <span nz-icon nzType="loading" class="loading-icon"></span>
                                  Loading Data...
                                </nz-option>
                              </nz-select>
                            </nz-form-control>
                            <ng-template #cp_country let-selected>
                              {{ selected.nzValue.name}}
                            </ng-template>
                          </nz-form-item>
                        </div>
  
                        <div class="form-group">
                          <div class="search_icon">
                            <span nz-icon nzType="search" nzTheme="outline"></span>
                          </div>
                          <nz-form-item>
                            <nz-form-label>
                              <div class="location_label">
                                <img src="../../../assets/images/location-pin.svg" alt="">
                                <span>
                                  Exclude
                                </span>
                              </div>
                            </nz-form-label>
                            <nz-form-control nzErrorTip="Please select exclude location">
                              <nz-select class="tag_items_layout" [nzAllowClear]="false" nzDropdownClassName="default_dropdown_border"
                                nzShowSearch nzAllowClear nzServerSearch formControlName="excluded_countries"
                                nzMode="multiple" [nzCustomTemplate]="cp_country_ex"
                                [nzPlaceHolder]="'Type to add more exclude location'"
                                [(ngModel)]="selectedExcludedCountries" (ngModelChange)="updateLinkedinEstimatedReach()"
                                (nzOnSearch)="getCountriesExclude($event)" (nzOpenChange)="sharedService.openNzSelect($event)">
                                <nz-option *ngFor="let country of countriesListExclude" nzCustomContent [nzValue]="country">
                                  <div class="default_font">
                                    <h5>{{ country.name }}</h5>
                                  </div>
                                </nz-option>
                                <nz-option *ngIf="isExcludedCountriesLoading" nzDisabled nzCustomContent>
                                  <span nz-icon nzType="loading" class="loading-icon"></span>
                                  Loading Data...
                                </nz-option>
                              </nz-select>
                            </nz-form-control>
                            <ng-template #cp_country_ex let-selected>
                              {{ selected.nzValue.name}}
                            </ng-template>
                          </nz-form-item>
                        </div>
                      </div>
                      <div class="location_title">
                        <h5>Gender</h5>
                        <p>Show your ads to either men or women, or select "All" for both.</p>
                      </div>
                      <div class="gender_selection">
                        <nz-form-item>
                          <nz-form-control nzErrorTip="Please select gender">
                            <nz-radio-group formControlName="genders" [(ngModel)]="gender"
                              (ngModelChange)="updateLinkedinEstimatedReach()">
                              <label nz-radio nzValue="ALL">All</label>
                              <label nz-radio nzValue="MALE">Male</label>
                              <label nz-radio nzValue="FEMALE">Female</label>
                            </nz-radio-group>
                          </nz-form-control>
                        </nz-form-item>
                      </div>
                      <div class="location_title">
                        <h5>Age</h5>
                        <p>Select age group of the people who will find your ad relevant.</p>
                      </div>
                      <nz-form-control>
                        <nz-select class="tag_items_layout" [nzAllowClear]="false" nzDropdownClassName="default_dropdown_border"
                          nzShowSearch nzAllowClear nzServerSearch formControlName="age_group" nzMode="multiple"
                          [nzCustomTemplate]="cp_age_group" [nzPlaceHolder]="'Select age group'"
                          [(ngModel)]="selectedAgeGroup" (ngModelChange)="updateLinkedinEstimatedReach()" (nzOpenChange)="sharedService.openNzSelect($event)">  
                          <nz-option *ngFor="let option of ageOptions" nzCustomContent [nzValue]="option">
                            <div class="default_font">
                              <h5>{{ option.label }}</h5>
                            </div>
                          </nz-option>
                        </nz-select>
                        <div *ngIf="adForm.get('age_group').hasError('required') && (adForm.get('age_group').dirty || adForm.get('age_group').touched)">
                          <div class="error-message">Please select age group.</div>
                        </div>
                      </nz-form-control>
                      <ng-template #cp_age_group let-selected>
                        {{ selected.nzValue.label}}
                      </ng-template>
                    </div>
                  </div>
                  <div class="campaign_card">
                    <div class="campaign_card_header">
                      <h5>Total Budget</h5>
                    </div>
                    <div class="campaign_card_body">
                      <div>
                        <p>The maximum amount you'll spend on your ad until you stop running it.</p>
                      </div>
                      <div class="budget_main">
                        <div class="form-group budget_input">
                          <nz-form-item>
                            <nz-form-control nzErrorTip="Please add your budget">
                              <input class="ant-number" nz-input placeholder="Budget"
                                     type="number" formControlName="budget" min="0"/>
                              <span class="dollar_icon">{{ selectedAdAccount.currency_symbol }}</span>
                            </nz-form-control>
                          </nz-form-item>
                        </div>
  
                        <div class="form-group">
                          <nz-form-item>
                            <nz-form-control nzErrorTip="Please select budget type">
                              <nz-radio-group formControlName="budget_type" [(ngModel)]="selectedBudgetType" [nzDisabled]="campaign">
                                <label nz-radio nzValue="daily">Daily</label>
                                <label nz-radio nzValue="lifetime">Lifetime</label>
                              </nz-radio-group>
                            </nz-form-control>
                          </nz-form-item>
                        </div>
  
                      </div>
  
                      <!--<ng-container *ngIf="selectedBudgetType==='lifetime'">-->
                      <ng-container>
                        <div class="location_title">
                          <h5>Duration</h5>
                          <p>Enter how long you want your promotion to run</p>
                        </div>
                        <div class="ent_time_picker">
                          <nz-form-item>
                            <nz-form-label>From</nz-form-label>
                            <nz-form-control nzErrorTip="Please select date">
                              <nz-date-picker [nzDisabledDate]="disabledDate"
                                              [(ngModel)]="start_time" [class.disabled_while_loading]="campaign" [nzDisabled]="campaign"
                                              [nzShowTime]="{ nzFormat: 'h:mm a', nzUse12Hours: true }"
                                              formControlName="start_time"></nz-date-picker>
                            </nz-form-control>
                          </nz-form-item>
  
                          <nz-form-item>
                            <nz-form-label>To</nz-form-label>
                            <nz-form-control nzErrorTip="Please select date">
                              <nz-date-picker [nzDisabledDate]="disabledDate"
                                              [(ngModel)]="end_time"
                                              nzFormat="yyyy-MM-dd h:mm a"
                                              [nzShowTime]="{ nzFormat: 'h:mm a', nzUse12Hours: true }"
                                              formControlName="end_time"></nz-date-picker>
                            </nz-form-control>
                          </nz-form-item>
                        </div>
                      </ng-container>
  
                    </div>
                  </div>
                  <div class="campaign_card">
                    <div class="campaign_card_header">
                      <h5>Payment</h5>
                    </div>
                    <div class="campaign_card_body">
                      <div>
                        <p>When you promote a post, LinkedIn charges the associated LinkedIn ad account linked to your LinkedIn Page. To review or adjust your payment settings, visit the LinkedIn Ads Manager.
                          <a target="_blank" href="https://www.linkedin.com/help/learning/answer/a1561057">Learn More.</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </ng-container>
  
              </form>
            </div>
          </div>
          <div class="campaign_wrapper_right">
            <!-- *ngIf="selectedAdAccount" -->
            <div class="campaign_wrapper_right_in">
  
              <!--No Ad Account-->
              <div class="campaign_card" *ngIf="!selectedAdAccount">
                <div class="campaign_card_body">
                  <div class="before_preview_wrapper">
                    <div class="before_preview">
                      <img src="../../../assets/images/add_account.png" alt="">
                      <span>Select an Ad Account to preview this ad</span>
                    </div>
                  </div>
                </div>
              </div>
              <!--Potential reach-->
              <div class="campaign_card" *ngIf="selectedAdAccount">
  
                <div class="campaign_card_header">
                  <h5>Potential reach</h5>
                </div>
                <div class="campaign_card_body">
                  <div>
                    <p>Your audience targeting is incomplete. Select at least one location, or choose a Custom Audience.
                    </p>
                  </div>
                  <div class="location_title">
                    <h5>Target Audience Size<span style="margin-left: 7px;" nz-tooltip
                                   nzTooltipTitle=""
                                   nz-icon nzType="info-circle" nzTheme="fill"></span></h5>
                  </div>
                  <div class="reach_count">
                    <ng-container *ngIf="isLinkedinReachLoading">
                      <div class="est_reach_skeleton">
                        <nz-skeleton [nzActive]="true" [nzParagraph]="{ rows: 0 }"></nz-skeleton>
                      </div>
                    </ng-container>
                    <span>{{ estimatedReach }}</span>
                  </div>
                </div>
              </div> 

              <!--Ad Preview -->
              <div class="campaign_card" *ngIf="campaign">
                <div class="campaign_card_header">
                  <h5>Preview</h5>
                </div>
                <div class="campaign_card_body">
                  <ng-container *ngIf="!isAdPreviewLoading;else loadingPreview">
                    <div *ngFor="let adPreview of adPreviews">
                      <!-- <span>{{ adPreview.type }}</span> -->
                      <div [innerHTML]="adPreview.preview" class="campaign_iframe"></div>
                    </div>
                  </ng-container>
                  <ng-template #loadingPreview>
                    <div class="preview_skeleton">
                      <nz-skeleton [nzActive]="true" [nzParagraph]="{ rows: 0 }"></nz-skeleton>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div class="campaign_footer">
    <div class="campaign_footer_left">
      <button class="btn_primary_outline" (click)="closeModal()"
              [class.disabled_while_loading]="isAdSaving">Cancel</button>
    </div>
    <div class="campaign_footer_right">
  
      <button class="btn_primary" *ngIf="!campaign"
        [class.disabled_while_loading]="isAdSaving" (click)="saveLinkedinAd()">Save
        <ng-container *ngIf="isAdSaving">
          <span nz-icon nzType="loading"></span>
        </ng-container>
      </button>
  
      <button class="btn_primary" *ngIf="campaign" [class.disabled_while_loading]="isAdSaving" (click)="updateLinkedinAd()"> Update
        <ng-container *ngIf="isAdSaving">
          <span nz-icon nzType="loading"></span>
        </ng-container>
      </button>
  
    </div>
  </div>
  