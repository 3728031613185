<!--<div class="team-invite-drawer">
  <div class="team-invite-steps">
    <nz-steps [nzCurrent]="currentStep">
      <nz-step nzTitle="Invite & Access & Authority"></nz-step>
      <nz-step nzTitle="Assign Team & Finish"></nz-step>
    </nz-steps>
  </div>

  <div class="first_step" *ngIf="currentStep === 0">

    <div class="team-invite-drawer-item">
      &lt;!&ndash; <div class="team-invite-drawer-title" *ngIf="editId == 0">
        <h2>Invite</h2>
      </div> &ndash;&gt;
      <div class="team-invite-drawer-content" *ngIf="editId === 0">
        <form nz-form [formGroup]="step1Form">
          <nz-form-item>
            <nz-form-label [nzSpan]="24">Team Members Email</nz-form-label>
            <nz-form-control [nzErrorTip]="emailError" [nzSpan]="24">
              <input nz-input formControlName="email" required />
              <ng-template #emailError let-control>
                <ng-container *ngIf="control.hasError('required')">
                  This Email Field is required!
                </ng-container>
                <ng-container *ngIf="control.hasError('pattern')">
                  Please Enter Valid Email!
                </ng-container>
              </ng-template>
            </nz-form-control>
          </nz-form-item>
        </form>
      </div>
    </div>
    <div class="team-invite-drawer-item">
      <div class="team-invite-drawer-title">
        <h2>Organization Role</h2>
      </div>
      <div class="team-invite-drawer-content">
        <div class="select-role-wrapper">
          <nz-radio-group [ngModel]="memberType" (ngModelChange)="changeOrganizationRole($event)">
            <div class="select-role-item">
              <label nz-radio nzValue="Admin">
                <div class="selectrole-box">
                  <h4>Admin</h4>
                  <p>Admin rights on all social profiles included in the organization. Admin can invite new team members
                    and can add new profile to the organization.</p>
                </div>
              </label>
            </div>
            <div class="select-role-item">
              <label nz-radio nzValue="Member">
                <div class="selectrole-box">
                  <h4>Member</h4>
                  <p>A Member can have unique permissions on each profile: Editor, Moderator, Guest. A Member can not
                    invite new team members and can not add new profiles to the organization.</p>
                </div>
              </label>
            </div>

            <div class="select-role-item">
              <label nz-radio nzValue="Custom">
                <div class="selectrole-box">
                  <h4>Custom Member</h4>
                  <p>Custom member can have unique role on each profile. You can assign Editor or Moderator or Guest
                    role for each social profile. A Custom Member can not invite new team members and can not add new
                    profiles to the organization.</p>
                </div>
              </label>
            </div>
          </nz-radio-group>

        </div>
      </div>
    </div>
    <div class="team-invite-drawer-item">
      <div class="team-invite-drawer-title">
        <h2>Profile Role</h2>
      </div>
      <div class="team-invite-drawer-content table-wrapper profile_role_main">
        <div class="drawer-table">
          <div class="drawer-table-header">
            <div class="drawer-table-row">
              <div class="drawer-table-row-sec-1">
                <div class="drawer-table-check-all">
                  <nz-switch (ngModelChange)="toggleProfiles($event)" [nzDisabled]="isDisabled "
                    [ngModel]="switchState">
                  </nz-switch>
                </div>
                <div class="drawer-table-profile"><span> All Profile</span></div>
              </div>
              <div class="drawer-table-row-sec-2">
                <nz-radio-group (ngModelChange)="selectedRole($event)" [(ngModel)]="roleValue">
                  <ng-container *ngIf="memberType === 'Custom'">
                    <label nz-radio [nzValue]="o.value" nzDisabled="true" *ngFor="let o of roleOptions">
                      <label nz-tooltip [nzTooltipTitle]="o.tooltip_text">{{o.label}}</label>
                    </label>
                  </ng-container>
                  <ng-container *ngIf="memberType !== 'Custom'">
                    <label nz-radio [nzValue]="o.value" [nzDisabled]="o.disabled" *ngFor="let o of roleOptions">
                      <span nz-tooltip [nzTooltipTitle]="o.tooltip_text">{{o.label}}</span>
                    </label>
                  </ng-container>
                </nz-radio-group>
              </div>
            </div>
          </div>
          <div class="drawer-table-body">
            <ng-container *ngIf="socialAccounts.length != 0">
              <div class="drawer-table-row" *ngFor="let accounts of socialAccounts">
                <div class="drawer-table-row-sec-1">
                  <div class="drawer-table-check-all">
                    <nz-switch [nzDisabled]="isDisabled" (ngModelChange)="clickSwitch($event,accounts.id)"
                      [ngModel]="enabledSocialNetworks[accounts.id]"></nz-switch>
                  </div>
                  <div class="drawer-table-profile">
                    <div class="img_sec">
                      <app-image-preview [isVideo]="false" [imageUrl]="accounts.image" [class]="'account-list-image'">
                      </app-image-preview>
                      <ng-container [ngTemplateOutlet]="providers" [ngTemplateOutletContext]="{accounts:accounts}">
                      </ng-container>
                    </div>
                    <div class="dtl_sec">
                      <h4>{{accounts.name}}</h4>
                      <span>{{accounts.type | titlecase}}</span>
                    </div>
                  </div>
                </div>
                <div class="drawer-table-row-sec-2">
                  <ng-container *ngIf="memberType === '' || memberType === 'Admin' || memberType === 'Member' ">
                    <nz-radio-group [(ngModel)]="roleValue">
                      <ng-container *ngIf="enabledSocialNetworks[accounts.id]">
                        <label nz-radio [nzValue]="o.value" [nzDisabled]="o.disabled" *ngFor="let o of roleOptions">{{
                          o.label }}</label>
                      </ng-container>

                      <ng-container *ngIf="!enabledSocialNetworks[accounts.id]">
                        <label nz-radio nzDisabled="true" *ngFor="let o of roleOptions">{{ o.label }}</label>
                      </ng-container>

                    </nz-radio-group>
                  </ng-container>

                  <ng-container *ngIf="memberType === 'Custom'">
                    <nz-radio-group [(ngModel)]="customRoles[accounts.id]">
                      <ng-container *ngIf="enabledSocialNetworks[accounts.id]">
                        <label nz-radio [nzValue]="o.value" [nzDisabled]="o.disabled" *ngFor="let o of roleOptions">{{
                          o.label }}</label>
                      </ng-container>

                      <ng-container *ngIf="!enabledSocialNetworks[accounts.id]">
                        <label nz-radio nzDisabled="true" *ngFor="let o of roleOptions">{{ o.label }}</label>
                      </ng-container>

                    </nz-radio-group>
                  </ng-container>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="socialAccounts.length == 0">
              <span style="align-items: center;">
                <app-empty [message]="'No Account Connected !'"></app-empty>
              </span>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="invite_btn_group text-right">
      &lt;!&ndash; <button nz-button class="btn btn_cancel" (click)="closeModal()" nzType="primary" >
        Cancel
      </button> &ndash;&gt;


      <button nz-button class="btn btn_next" nzType="primary" (click)="updateInvite(currentStep)" *ngIf="editId > 0"
        [disabled]="isLoadingUpdate">
        Next
        <ng-container *ngIf="isLoadingUpdate">
          <span nz-icon nzType="loading"></span>
        </ng-container>
      </button>


      <button nz-button class="btn btn_next" nzType="primary"
        [class.disabled_while_loading]="socialAccounts.length == 0" (click)="saveInviteAccess(currentStep)"
        *ngIf="editId === 0" [disabled]="isLoading">
        Next
        <ng-container *ngIf="isLoading ">
          <span nz-icon nzType="loading"></span>
        </ng-container>
      </button>
    </div>
  </div>

  <div nz-row [nzGutter]="16" class="last_step" *ngIf="currentStep === 1">
    <div class="gutter-row" nz-col nzSpan="24" [nzXs]="24" [nzSm]="24" [nzMd]="24" [nzLg]="24" [nzXl]="24">
      <div class="part-title">
        <h4>Organization Team</h4>
      </div>
    </div>
    <div class="gutter-row" nz-col nzSpan="24" [nzXs]="24" [nzSm]="24" [nzMd]="12" [nzLg]="8" [nzXl]="6"
      *ngFor="let team of teams">
      <div class="manage-team-box" droppable (onDrop)="onItemDrop($event,team)">
        <div class="manage-team-box-head">
          <app-image-preview [isVideo]="false" [teamProfile]="true" [imageUrl]="team.icon"
            [class]="'manage-team-box-logo'">
          </app-image-preview>
          <h4>{{team.team_name}}</h4>
        </div>
        <div class="manage-team-box-body">
          <div class="drag_label_main">
            <span>Drag & Drop to Add Account</span>
          </div>
          <perfect-scrollbar style="max-height: 300px">
            <ul class="panel-body">
              <li draggable (onDragEnd)="onDragEnds(item,team.id)" *ngFor="let item of droppedItems[team.id]"
                [dragData]="item" class="list-group-item">

                <div class="selected_profile_block">
                  <div class="img_sec">
                    <app-image-preview [isVideo]="false" [imageUrl]="item?.image" [class]="'account-list-image'">
                    </app-image-preview>
                    <app-provider [className]="'icon'" [provider]="item?.provider"></app-provider>
                  </div>
                  <div class="dtl_sec">
                    <h4>{{item?.name}}</h4>
                    <span>{{item?.type | titlecase}}</span>
                  </div>
                </div>
              </li>
            </ul>
          </perfect-scrollbar>
        </div>
      </div>
    </div>
    <ng-container *ngIf="teams.length == 0">
      <div class="create_gallery_albums">
        <div class="create_gallery_albums_in">
          <img src="../../../assets/images/create_gallery_album_img.png" alt="">
          <button nz-button class="btn_album" (click)="redirectToTeam()">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 6.75H6.75V12H5.25V6.75H0V5.25H5.25V0H6.75V5.25H12V6.75Z" fill="#494ADF" />
            </svg>
            Create New Team
          </button>
        </div>
      </div>
    </ng-container>
    <div class="gutter-row" nz-col nzSpan="24" [nzXs]="24" [nzSm]="24" [nzMd]="12" [nzLg]="8" [nzXl]="6"
      *ngIf="teams.length > 0 && memberType !== 'Admin'">
      <div class="manage-team-box drop-delete-box">
        <div class="drop-file">
          <i nz-icon nzType="delete" nzTheme="outline"></i>
          <span>Drop to Delete</span>
        </div>
      </div>
    </div>

    <div class="seleced_profile_sec gutter-row">
      <div nz-col nzSpan="24" [nzXs]="24" [nzSm]="24" [nzMd]="24" [nzLg]="24" [nzXl]="24">
        <div class="part-title">
          <h4>Selected Profile</h4>
        </div>
      </div>
      <div class="selected_profile_drag_main" [class.profileEmpty]="selectedMedias.length === 0">
        <div class="selected_profile_drag_in" *ngFor="let accounts of selectedMedias">
          <div draggable [dragData]="accounts">
            <div class="selected_profile_block">
              <div class="img_sec">
                <app-image-preview [isVideo]="false" [imageUrl]="accounts?.image" [class]="'account-list-image'">
                </app-image-preview>
                <ng-container [ngTemplateOutlet]="providers" [ngTemplateOutletContext]="{accounts:accounts}">
                </ng-container>
              </div>
              <div class="dtl_sec">
                <h4>{{accounts?.name}}</h4>
                <span>{{accounts?.type | titlecase}}</span>
              </div>
            </div>
          </div>
        </div>
        <ng-container *ngIf="selectedMedias.length === 0">
          <span >
            <app-empty [message]="'No more accounts are remaining !'"></app-empty>
          </span>
        </ng-container>
      </div>
    </div>


    <div class="gutter-row" nz-col nzSpan="24" [nzXs]="24" [nzSm]="24" [nzMd]="24" [nzLg]="24" [nzXl]="24">
      <div class="invite_btn_group text-right steps-btn-wrapper">
        <button nz-button class="btn btn_cancel" nzType="primary" (click)="backStep()"
          [disabled]="isLoading || isLoadingUpdate">
          Back
        </button>
        <button nz-button class="btn" nzType="primary" (click)="updateInvite(currentStep)" *ngIf="editId > 0"
          [disabled]="isLoadingUpdate">
          Finish
          <ng-container *ngIf="isLoadingUpdate">
            <span nz-icon nzType="loading"></span>
          </ng-container>
        </button>
        <button nz-button class="btn" nzType="primary" (click)="saveInviteAccess(currentStep)" *ngIf="editId === 0"
          [disabled]="isLoading">
          Finish
          <ng-container *ngIf="isLoading ">
            <span nz-icon nzType="loading"></span>
          </ng-container>
        </button>
      </div>
    </div>
  </div>
</div>-->


<ng-container>
  <div class="first_step">

    <div class="team-invite-drawer-item">
      <!-- <div class="team-invite-drawer-title" *ngIf="editId == 0">
      <h2>Invite</h2>
    </div> -->
      <div class="team-invite-drawer-content" *ngIf="editId === 0">
        <div class="team-invite-drawer-content-in">
          <form nz-form [formGroup]="inviteForm">
            <ng-container formArrayName="users">
              <ng-container *ngFor="let userForm of users.controls; let i = index">
                <div class="in_add_members" [formGroupName]="i">
                  <div class="in_add_members_left">
                    <div class="in_add_members_left_in">
                      <div>
                        <nz-form-item>
                          <nz-form-label [nzSpan]="24">Name</nz-form-label>
                          <nz-form-control [nzErrorTip]="nameError" [nzSpan]="24">
                            <input nz-input formControlName="name" placeholder="Enter name" />
                            <ng-template #nameError let-control>
                              <ng-container *ngIf="control.hasError('required')">
                                This Name Field is required!
                              </ng-container>
                              <ng-container *ngIf="control.hasError('pattern')">
                                Please Enter Valid Name!
                              </ng-container>
                            </ng-template>
                          </nz-form-control>
                        </nz-form-item>
                      </div>
                      <div>
                        <nz-form-item>
                          <nz-form-label [nzSpan]="24">Email</nz-form-label>
                          <nz-form-control [nzErrorTip]="emailError" [nzSpan]="24">
                            <input nz-input formControlName="email" placeholder="Enter email" />
                            <ng-template #emailError let-control>
                              <ng-container *ngIf="control.hasError('required')">
                                This Email Field is required!
                              </ng-container>
                              <ng-container *ngIf="control.hasError('pattern')">
                                Please Enter Valid Email!
                              </ng-container>
                            </ng-template>
                          </nz-form-control>
                        </nz-form-item>
                      </div>
                    </div>
                  </div>
                  <div class="in_add_members_right">
                    <button class="btn_secondary" nzType="primary" *ngIf="i===0" (click)="addUser()">
                      <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M16.4333 7.23333H9.76666V0.566666C9.76666 0.255 9.51167 0 9.2 0H7.8C7.48833 0 7.23333 0.255 7.23333 0.566666V7.23333H0.566667C0.255001 7.23333 0 7.48833 0 7.8V9.2C0 9.51167 0.255001 9.76667 0.566667 9.76667H7.23333V16.4333C7.23333 16.745 7.48833 17 7.8 17H9.2C9.51167 17 9.76666 16.745 9.76666 16.4333V9.76667H16.4333C16.745 9.76667 17 9.51167 17 9.2V7.8C17 7.48833 16.745 7.23333 16.4333 7.23333Z"
                          fill="#494ADF" />
                      </svg>
                      <span>Add</span>
                    </button>
                    <button class="btn_danger" nzType="primary" *ngIf="i!==0" (click)="deleteUser(i)">
                      <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M6 4L6.544 2.368C6.67664 1.96975 6.93123 1.62333 7.27169 1.37781C7.61216 1.13229 8.02124 1.00011 8.441 1H10.558C10.9779 0.999899 11.3872 1.13198 11.7279 1.37752C12.0686 1.62305 12.3233 1.96959 12.456 2.368L13 4M3.5 4L4.434 17.071C4.45194 17.323 4.56462 17.5589 4.74939 17.7313C4.93417 17.9036 5.17733 17.9996 5.43 18H13.568C13.8208 17.9999 14.0643 17.904 14.2492 17.7316C14.4342 17.5592 14.547 17.3232 14.565 17.071L15.5 4H3.5ZM9.5 9V13V9ZM17.5 4H1.5H17.5Z"
                          stroke="#EB3343" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                      <span>Delete</span>
                    </button>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </form>
          <div nz-row>
            <div nz-col nzSpan="24">
              <nz-form-item class="invt_team_field">
                <nz-form-label [nzSpan]="24">Teams</nz-form-label>
                <nz-form-control>
                  <nz-select (nzOpenChange)="sharedService.openNzSelect($event)" [nzShowArrow]="true" [nzCustomTemplate]="multipleTemplateTeam"
                            nzDropdownClassName="team_social_networks" nzMode="multiple" [(ngModel)]="selectedTeams"
                            (ngModelChange)="updateTeams($event)" [nzPlaceHolder]="isLoadingTeam ? 'Teams Loading..' : 'Select team'" [disabled]="isLoadingTeam">
                    <nz-option *ngFor="let team of teams" nzCustomContent [nzLabel]="team.team_name" [nzValue]="team">
                      <div class="user_dtl_main">
                        <div class="user_dtl_left">
                          <app-image-preview [imageUrl]="team?.icon" [class]="'profile-image'"
                                            [isVideo]="false"></app-image-preview>
                        </div>
                        <div class="user_dtl_right">
                          <h5>{{ team.team_name }}</h5>
                        </div>
                      </div>
                    </nz-option>
                  </nz-select>
                </nz-form-control>
                <ng-template #multipleTemplateTeam let-selected>
                  <div class="ant-select-selection-item-content">
                    <div class="cp_team_main">
                      <div class="user_selected">
                        <app-image-preview [imageUrl]="selected.nzValue?.icon" [class]="'profile-image'"
                                          [isVideo]="false"></app-image-preview>
                      </div>
                      <div class="content_sec">
                        <h5>{{selected.nzValue?.team_name}}</h5>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </nz-form-item>

              <div class="team_membr_list_main">
                <h5>Added Social Accounts ({{selectedSocialAccounts.length}})</h5>
                <div class="social_profile_list res_social_account">
                  <table>
                    <thead>
                    <tr>
                      <th>Social Profile</th>
                      <th class="res_th">Profile Permissions</th>
                      <th class="web_th">Action</th>
                      <th class="text-right ">
                        <div class="select_all_member res_th">
                          <span>Select All</span>
                          <label nz-checkbox [(ngModel)]="allSocialChecked"
                                (ngModelChange)="selectAllSocialAccount($event)"
                                [disabled]="socialAccounts.length==0"></label>
                        </div>
                      </th>
                    </tr>
                    </thead>

                    <tbody *ngIf="socialAccounts.length >0">
                    <tr *ngFor="let account of socialAccounts">
                      <td>
                        <div class="tm_first_member">
                          <div class="tm_first_img">
                            <app-image-preview [imageUrl]="account.image" [isVideo]="false"></app-image-preview>
                            <app-provider [iconSize]="sharedService.mediumIcon" [provider]="account.provider"></app-provider>
                          </div>
                          <div class="tm_first_label">
                            <span>{{account.name}}</span>
                          </div>
                        </div>
                      </td>
                      <td class="res_td">
                        <nz-select (nzOpenChange)="sharedService.openNzSelect($event)" nzDropdownClassName="rp_option_main" nzPlaceHolder="Select Roles & Permissions"
                                  [nzCustomTemplate]="multipleTemplateRolesPermissions" [(ngModel)]="account.social_access_id">
                          <nz-option *ngFor="let accessType of sharedService.socialAccessTypes; let i=index;"
                                    nzCustomContent [nzValue]="i">
                            <div class="rp_main">
                              <h5>{{ accessType }}</h5>
                              <span>{{ sharedService.socialAccessTypesDescription[i] }}</span>
                            </div>
                          </nz-option>
                        </nz-select>
                        <ng-template #multipleTemplateRolesPermissions let-selected>
                          <div class="ant-select-selection-item-content">
                            <span>{{sharedService.socialAccessTypes[selected.nzValue]}}</span>
                          </div>
                        </ng-template>
                      </td>
                      <td class="web_td">
                        <a href="javascript:;" (click)="socialProfileAction(account)">
                          <svg width="21" height="17" viewBox="0 0 21 17" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M20.5832 7.89531C18.3613 3.21484 15.0027 0.859375 10.5004 0.859375C5.99569 0.859375 2.63944 3.21484 0.417567 7.89766C0.328447 8.08637 0.282227 8.29247 0.282227 8.50117C0.282227 8.70987 0.328447 8.91598 0.417567 9.10469C2.63944 13.7852 5.99804 16.1406 10.5004 16.1406C15.0051 16.1406 18.3613 13.7852 20.5832 9.10234C20.7637 8.72266 20.7637 8.28203 20.5832 7.89531ZM10.5004 14.4531C6.71991 14.4531 3.95194 12.5359 1.9996 8.5C3.95194 4.46406 6.71991 2.54688 10.5004 2.54688C14.2808 2.54688 17.0488 4.46406 19.0012 8.5C17.0512 12.5359 14.2832 14.4531 10.5004 14.4531ZM10.4066 4.375C8.1285 4.375 6.28163 6.22188 6.28163 8.5C6.28163 10.7781 8.1285 12.625 10.4066 12.625C12.6848 12.625 14.5316 10.7781 14.5316 8.5C14.5316 6.22188 12.6848 4.375 10.4066 4.375ZM10.4066 11.125C8.95585 11.125 7.78163 9.95078 7.78163 8.5C7.78163 7.04922 8.95585 5.875 10.4066 5.875C11.8574 5.875 13.0316 7.04922 13.0316 8.5C13.0316 9.95078 11.8574 11.125 10.4066 11.125Z"
                              fill="#494ADF" />
                          </svg>
                        </a>
                      </td>
                      <td class="text-right ">
                        <label nz-checkbox [(ngModel)]="account.is_checked"
                              (ngModelChange)="selectSocialAccount($event)"></label>
                      </td>
                    </tr>
                    </tbody>
                  </table>

                  <ng-container *ngIf="socialAccountLoading">
                    <div class="analytics_post_skeleton account_skeleton">
                      <nz-skeleton [nzAvatar]="true" [nzParagraph]="{ rows: 1 }" *ngFor="let item of dummyItems"></nz-skeleton>
                    </div>
                  </ng-container>

                  <div *ngIf="!socialAccountLoading && socialAccounts?.length === 0">
                    <app-empty [message]="'No Social Profile Found !'"></app-empty>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>

        <div>
          <!-- inviteForm.invalid || -->
          <button class="btn_primary" (click)="sendInvites()" [disabled]=" isLoadingInvite">Send
            Invites
            <ng-container *ngIf="isLoadingInvite">
              <span nz-icon nzType="loading"></span>
            </ng-container>
          </button>
        </div>
      </div>
    </div>

    <!--<div class="team-invite-drawer-item">
    <div class="team-invite-drawer-title">
      <h2>Organization Role</h2>
    </div>
    <div class="team-invite-drawer-content">
      <div class="select-role-wrapper">
        <nz-radio-group [ngModel]="memberType" (ngModelChange)="changeOrganizationRole($event)">
          <div class="select-role-item">
            <label nz-radio nzValue="Admin">
              <div class="selectrole-box">
                <h4>Admin</h4>
                <p>Admin rights on all social profiles included in the organization. Admin can invite new team members
                  and can add new profile to the organization.</p>
              </div>
            </label>
          </div>
          <div class="select-role-item">
            <label nz-radio nzValue="Member">
              <div class="selectrole-box">
                <h4>Member</h4>
                <p>A Member can have unique permissions on each profile: Editor, Moderator, Guest. A Member can not
                  invite new team members and can not add new profiles to the organization.</p>
              </div>
            </label>
          </div>

          <div class="select-role-item">
            <label nz-radio nzValue="Custom">
              <div class="selectrole-box">
                <h4>Custom Member</h4>
                <p>Custom member can have unique role on each profile. You can assign Editor or Moderator or Guest
                  role for each social profile. A Custom Member can not invite new team members and can not add new
                  profiles to the organization.</p>
              </div>
            </label>
          </div>
        </nz-radio-group>

      </div>
    </div>
  </div>
  <div class="team-invite-drawer-item">
    <div class="team-invite-drawer-title">
      <h2>Profile Role</h2>
    </div>
    <div class="team-invite-drawer-content table-wrapper profile_role_main">
      <div class="drawer-table">
        <div class="drawer-table-header">
          <div class="drawer-table-row">
            <div class="drawer-table-row-sec-1">
              <div class="drawer-table-check-all">
                <nz-switch (ngModelChange)="toggleProfiles($event)" [nzDisabled]="isDisabled "
                           [ngModel]="switchState">
                </nz-switch>
              </div>
              <div class="drawer-table-profile"><span> All Profile</span></div>
            </div>
            <div class="drawer-table-row-sec-2">
              <nz-radio-group (ngModelChange)="selectedRole($event)" [(ngModel)]="roleValue">
                <ng-container *ngIf="memberType === 'Custom'">
                  <label nz-radio [nzValue]="o.value" nzDisabled="true" *ngFor="let o of roleOptions">
                    <label nz-tooltip [nzTooltipTitle]="o.tooltip_text">{{o.label}}</label>
                  </label>
                </ng-container>
                <ng-container *ngIf="memberType !== 'Custom'">
                  <label nz-radio [nzValue]="o.value" [nzDisabled]="o.disabled" *ngFor="let o of roleOptions">
                    <span nz-tooltip [nzTooltipTitle]="o.tooltip_text">{{o.label}}</span>
                  </label>
                </ng-container>
              </nz-radio-group>
            </div>
          </div>
        </div>
        <div class="drawer-table-body">
          <ng-container *ngIf="socialAccounts.length != 0">
            <div class="drawer-table-row" *ngFor="let accounts of socialAccounts">
              <div class="drawer-table-row-sec-1">
                <div class="drawer-table-check-all">
                  <nz-switch [nzDisabled]="isDisabled" (ngModelChange)="clickSwitch($event,accounts.id)"
                             [ngModel]="enabledSocialNetworks[accounts.id]"></nz-switch>
                </div>
                <div class="drawer-table-profile">
                  <div class="img_sec">
                    <app-image-preview [isVideo]="false" [imageUrl]="accounts.image" [class]="'account-list-image'">
                    </app-image-preview>
                    <ng-container [ngTemplateOutlet]="providers" [ngTemplateOutletContext]="{accounts:accounts}">
                    </ng-container>
                  </div>
                  <div class="dtl_sec">
                    <h4>{{accounts.name}}</h4>
                    <span>{{accounts.type | titlecase}}</span>
                  </div>
                </div>
              </div>
              <div class="drawer-table-row-sec-2">
                <ng-container *ngIf="memberType === '' || memberType === 'Admin' || memberType === 'Member' ">
                  <nz-radio-group [(ngModel)]="roleValue">
                    <ng-container *ngIf="enabledSocialNetworks[accounts.id]">
                      <label nz-radio [nzValue]="o.value" [nzDisabled]="o.disabled" *ngFor="let o of roleOptions">{{
                        o.label }}</label>
                    </ng-container>

                    <ng-container *ngIf="!enabledSocialNetworks[accounts.id]">
                      <label nz-radio nzDisabled="true" *ngFor="let o of roleOptions">{{ o.label }}</label>
                    </ng-container>

                  </nz-radio-group>
                </ng-container>

                <ng-container *ngIf="memberType === 'Custom'">
                  <nz-radio-group [(ngModel)]="customRoles[accounts.id]">
                    <ng-container *ngIf="enabledSocialNetworks[accounts.id]">
                      <label nz-radio [nzValue]="o.value" [nzDisabled]="o.disabled" *ngFor="let o of roleOptions">{{
                        o.label }}</label>
                    </ng-container>

                    <ng-container *ngIf="!enabledSocialNetworks[accounts.id]">
                      <label nz-radio nzDisabled="true" *ngFor="let o of roleOptions">{{ o.label }}</label>
                    </ng-container>

                  </nz-radio-group>
                </ng-container>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="socialAccounts.length == 0">
              <span style="align-items: center;">
                <app-empty [message]="'No Account Connected !'"></app-empty>
              </span>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="invite_btn_group text-right">
    &lt;!&ndash; <button nz-button class="btn btn_cancel" (click)="closeModal()" nzType="primary" >
      Cancel
    </button> &ndash;&gt;


    <button nz-button class="btn btn_next" nzType="primary" (click)="updateInvite(currentStep)" *ngIf="editId > 0"
            [disabled]="isLoadingUpdate">
      Next
      <ng-container *ngIf="isLoadingUpdate">
        <span nz-icon nzType="loading"></span>
      </ng-container>
    </button>


    <button nz-button class="btn btn_next" nzType="primary"
            [class.disabled_while_loading]="socialAccounts.length == 0" (click)="saveInviteAccess(currentStep)"
            *ngIf="editId === 0" [disabled]="isLoading">
      Next
      <ng-container *ngIf="isLoading ">
        <span nz-icon nzType="loading"></span>
      </ng-container>
    </button>
  </div>-->

  </div>
  <!--<div class="organization_form_action">
  &lt;!&ndash;<span>Are you sure you want to delete Draft post?</span>&ndash;&gt;
  <div class="btn_group">
    <button nz-button nzType="primary" class="btn" (click)="cancelPermissionModal()"
            [disabled]="isLoadingPermission">Cancel</button>
    <button nz-button nzType="primary" class="btn btn_apply" (click)="savePermissions()"
            [disabled]="selectedPermissionIds.length===0 || isLoadingPermission">Save
      <ng-container *ngIf="isLoadingPermission">
        <span nz-icon nzType="loading"></span>
      </ng-container>
    </button>
  </div>
</div>-->
</ng-container>


<!-- permission modal start -->
<nz-modal [(nzVisible)]="permissionModal" nzClassName="permissionModal" [nzFooter]=null>
  <ng-container *nzModalContent>
    <app-member-permission [users]="inviteForm.value.users" [team_ids]="team_ids"
                           (permissionChange)="permissionChange($event)" (premissionCancel)="permissionCancelEvent()" [mode]="1"></app-member-permission>
  </ng-container>
</nz-modal>
<!-- permission modal end  -->


<!-- delete draftpost start -->
<nz-modal [(nzVisible)]="deleteDraftPost" nzClassName="Delete Draft Post" [nzFooter]=null>
  <ng-container *nzModalContent>
    <div class="delete_team_hdr">
      <h4>Delete Draft post ?</h4>
    </div>
    <div class="delete_team_body">
      <p><strong> Please note -</strong> Your draft post will be removed.</p>
      <img src="../../../../assets/images/delete_img_1.png" alt="">

      <div class="organization_form_action">
        <span>Are you sure you want to delete Draft post?</span>
        <div class="btn_group">
          <button nz-button nzType="primary" class="btn" (click)="deleteDeaftPostClose()"
                  [disabled]="isLoader">Cancel</button>
          <button nz-button nzType="primary" class="btn btn_apply" (click)="deleteDraftPosts()"
                  [disabled]="isLoader">Yes, Delete It
            <ng-container *ngIf="isLoader">
              <span nz-icon nzType="loading"></span>
            </ng-container>
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</nz-modal>
<!-- delete album end  -->

<!-- Start social Profile action -->
<nz-modal [(nzVisible)]="socialAction" nzClassName="social_action_modal" [nzFooter]=null (nzOnCancel)="handleCancel()"
          nzTitle="Social Account">
  <ng-container *nzModalContent>
    <div class="social_acc_info">
      <div class="social_acc_info_in">
        <h3>Social Profile</h3>
        <div>
          <div class="tm_first_member">
            <div class="tm_first_img">
              <app-image-preview [imageUrl]="specificSocialAccount.image" [isVideo]="false"></app-image-preview>
              <app-provider [iconSize]="sharedService.mediumIcon" [provider]="specificSocialAccount.provider"></app-provider>
            </div>
            <div class="tm_first_label">
              <span>{{specificSocialAccount.name}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="social_acc_info_in">
        <h3>Roles & Permissions</h3>
        <div class="social_acc_permision">
          <nz-select nzDropdownClassName="rp_option_main" nzPlaceHolder="Select Roles & Permissions"
                     [nzCustomTemplate]="multipleTemplateRolesPermissions" [(ngModel)]="specificSocialAccount.social_access_id">
            <nz-option *ngFor="let accessType of sharedService.socialAccessTypes; let i=index;" nzCustomContent
                       [nzValue]="i">
              <div class="rp_main">
                <h5>{{ accessType }}</h5>
                <span>{{ sharedService.socialAccessTypesDescription[i] }}</span>
              </div>
            </nz-option>
          </nz-select>
          <ng-template #multipleTemplateRolesPermissions let-selected>
            <div class="ant-select-selection-item-content">
              <span>{{sharedService.socialAccessTypes[selected.nzValue]}}</span>
            </div>
          </ng-template>
        </div>
      </div>
      <div class="btn_wrap">
        <button type="button" class="btn btn_done"> Done </button>
      </div>
    </div>
  </ng-container>
</nz-modal>
<!-- End social Profile action -->
