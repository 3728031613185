<nz-table #accountsTable [nzData]="member_accounts" class="accounts_list_table table_default"
  [nzFrontPagination]="false">
  <thead *ngIf="team_account === '' || sharedService.isMobile">
    <tr>
      <th>Name</th>
      <th class="org_action" *ngIf="isUpdatePermission">Actions</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let account of member_accounts; let i = index">
      <tr>
        <td>
          <div class="user_account_name">

            <div class="img_sec">
              <app-image-preview [isVideo]="false" [imageUrl]="account.social_account?.image"
                [class]="'account-list-image'" *ngIf="account.social_account?.image !== null"></app-image-preview>
              <img src="assets/images/Profile.png" alt="" *ngIf="account.social_account?.image === null" class="default-profile-img">
              <app-provider [className]="'team_account_list_social_icon'" [iconSize]="sharedService.mediumIcon" [provider]="account.social_account?.provider"></app-provider>
            </div>

            <div class="name_wrp">
              <h5>{{ account.social_account?.name }}</h5>
              <span>{{ account.social_account?.type | titlecase }}</span>
            </div>
          </div>
        </td>
        <td *ngIf="isUpdatePermission">
          <div class="btn_wrap">
            <!-- <a (click)="deleteAccount(account.social_account?.id)" class="btn delete-account"> -->
            <a (click)="delete_social_account(account.social_account?.id)" class="btn delete-account">

              <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6 4L6.544 2.368C6.67664 1.96975 6.93123 1.62333 7.27169 1.37781C7.61216 1.13229 8.02124 1.00011 8.441 1H10.558C10.9779 0.999899 11.3872 1.13198 11.7279 1.37752C12.0686 1.62305 12.3233 1.96959 12.456 2.368L13 4M3.5 4L4.434 17.071C4.45194 17.323 4.56462 17.5589 4.74939 17.7313C4.93417 17.9036 5.17733 17.9996 5.43 18H13.568C13.8208 17.9999 14.0643 17.904 14.2492 17.7316C14.4342 17.5592 14.547 17.3232 14.565 17.071L15.5 4H3.5ZM9.5 9V13V9ZM17.5 4H1.5H17.5Z"
                  stroke="#494ADF" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <span>Delete Account</span>
            </a>
            &nbsp;
            <a (click)="viewMembers(account.social_account?.id)" class="btn edit-account">
              <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M20.5832 7.89531C18.3613 3.21484 15.0027 0.859375 10.5004 0.859375C5.99569 0.859375 2.63944 3.21484 0.417567 7.89766C0.328447 8.08637 0.282227 8.29247 0.282227 8.50117C0.282227 8.70987 0.328447 8.91598 0.417567 9.10469C2.63944 13.7852 5.99804 16.1406 10.5004 16.1406C15.0051 16.1406 18.3613 13.7852 20.5832 9.10234C20.7637 8.72266 20.7637 8.28203 20.5832 7.89531ZM10.5004 14.4531C6.71991 14.4531 3.95194 12.5359 1.9996 8.5C3.95194 4.46406 6.71991 2.54688 10.5004 2.54688C14.2808 2.54688 17.0488 4.46406 19.0012 8.5C17.0512 12.5359 14.2832 14.4531 10.5004 14.4531ZM10.4066 4.375C8.1285 4.375 6.28163 6.22188 6.28163 8.5C6.28163 10.7781 8.1285 12.625 10.4066 12.625C12.6848 12.625 14.5316 10.7781 14.5316 8.5C14.5316 6.22188 12.6848 4.375 10.4066 4.375ZM10.4066 11.125C8.95585 11.125 7.78163 9.95078 7.78163 8.5C7.78163 7.04922 8.95585 5.875 10.4066 5.875C11.8574 5.875 13.0316 7.04922 13.0316 8.5C13.0316 9.95078 11.8574 11.125 10.4066 11.125Z"
                  fill="white" />
              </svg>
              <span>View Members</span>
            </a>
          </div>
        </td>
      </tr>
    </ng-container>
  </tbody>
</nz-table>

<nz-modal nzClassName="associated_members_modal" [(nzVisible)]="isVisibleViewMembers" nzTitle="Associated Members"
  (nzOnCancel)="cancelModal()">
  <div *nzModalContent>
    <nz-table #accountsTable [nzData]="listOfMembers" class="table_default accounts_list_table"
      [nzFrontPagination]="false">
      <thead>
        <tr>
          <th>Name & Role</th>
          <!-- <th>Role</th> -->
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let member of listOfMembers; let i = index">
          <tr>
            <td>
              <div class="user_account_name">
                <div class="img_sec">
                  <app-image-preview [isVideo]="false" [imageUrl]="member.users.profile_pic"
                    [class]="'account-list-image'" *ngIf="member.users.profile_pic !== null"></app-image-preview>
                  <img src="assets/images/Profile.png" *ngIf="member.users.profile_pic === null"
                    class="default-profile-img">
                </div>
                <div class="name_wrp">
                  <h5>{{member.users.name}}</h5>
                  <span>{{member.role.role_name}}</span>
                </div>
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </nz-table>
  </div>
  <div *nzModalFooter></div>
</nz-modal>

<!-- Start delete team -->
<nz-modal [(nzVisible)]="deleteTeamvisible" nzClassName="delete_team_modal" [nzFooter]=null>
  <ng-container *nzModalContent>
    <div class="delete_team_hdr">
      <h4>Delete Account?</h4>
    </div>
    <div class="delete_team_body">
      <p>when you remove this social account, This social media account will be remove from ' {{this.team_label }} '.
        Are you sure you want to remove this account?</p>
      <img src="../../../../assets/images/delete_img_1.png" alt="">
      <div class="organization_form_action">
        <span>Are you sure you want to delete this account?</span>
        <div class="btn_group">
          <button nz-button nzType="primary" class="btn" (click)="deleteTeamModalClose()"
            [disabled]="isDeleteAccountLoader">Cancel</button>
          <button nz-button nzType="primary" class="btn btn_apply" (click)="deleteAccount(delete_account_id)"
            [disabled]="isDeleteAccountLoader">Yes, Delete It
            <ng-container *ngIf="isDeleteAccountLoader">
              <span nz-icon nzType="loading"></span>
            </ng-container>

          </button>
        </div>
      </div>
    </div>
  </ng-container>
</nz-modal>
<!-- End delete team -->
