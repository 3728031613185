import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import {Socket, io} from "socket.io-client";
import { SubscriptionClear, SubscriptionGet } from '../action-state/actions/subscription.action';
import {async, filter} from "rxjs";

import { AppWarningModalComponent } from '../component/app-warning-modal/app-warning-modal.component';
import { AuthService } from '../services/auth.service';
import { AuthState } from '../action-state/states/auth.state';
import { Constants } from 'src/app/constants';
import { FormBuilder } from '@angular/forms';
import { HelperService } from '../services/helper.service';
import { InAppPurchaseState } from "../action-state/states/in-app-purchase.state";
import { JoyrideService } from 'ngx-joyride';
import { Logout } from '../action-state/actions/auth.action';
import { NgxSpinnerService } from 'ngx-spinner';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { SharedService } from '../services/shared.service';
import { SocialAccountService } from '../services/social-account.service';
import { Store } from '@ngxs/store';
import { SubscriptionState } from "../action-state/states/subscription.state";
import { TeamState } from '../action-state/states/team.state';
import { TeamsService } from '../services/teams.service';
import { UserService } from '../services/user.service';
import { WasabiService } from '../services/wasabi.service';
import {environment} from "../../environments/environment";
import moment from 'moment';

@Component({
  selector: 'bs-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.less']
})

export class LayoutComponent implements OnInit,AfterViewInit {
  isMobile = this.sharedService.isMobile;
  isCollapsed = false;

  notifications: any = [];
  visible: any = false;
  organizations: any = [];
  superAdminOrg: any = [];
  selectedOrganization = null;
  selectedOrganizationModal = null;
  selectedValue: any = '';
  modalTitle = 'Create Team';
  iconUrl = null;
  organization_modal_preview = false;
  editOrganizationId = 0;
  organizationForm: any;
  loading = false;
  icon: NzUploadFile;
  user = null;
  userId = null;
  deleteIcon = false;
  teams: any = [];
  isOrganizations = null;
  allOrganizations: any = [];
  permissions = null;
  loggedUserRole = '';
  isTeamUpdatePermission = false;
  isTeamDeletePermission = false;
  isVisble = false;
  isInvitePermission = false;
  deleteOrgvisible = false;
  member_org_id = null;
  team_org_id = null;
  isDisableOrg = false;
  // isLoadingPost = false;
  isLoadingLogOut = false;

  isOpenMywork = false;
  isOpenMyAccount = false;
  isOpenMyNotification = false;

  pageName = '';
  selectedOrg:any

  assignedPosts = [];
  recentPosts = [];
  selectedTab=0;

  subscription = false;

  idleState = 'Not started.';
  timedOut = false;
  isAccountDropdownOpen = false;
  socket: Socket;
  needTeamTour = 0;
  isOrgPopover = false;
  isTeamTourActive = false;
  isOrgPopoverBefore = false;

  constructor(
    public router: Router,
    public store: Store,
    private authService: AuthService,
    private messageService: NzMessageService,
    private spinnerService: NgxSpinnerService,
    public sharedService: SharedService,
    private modal: NzModalService,
    private userService: UserService,
    private wasabiService: WasabiService,
    private helper: HelperService,
    private teamService: TeamsService, private formBuilder: FormBuilder,
    private socialAccountService: SocialAccountService,
    private activatedRoute: ActivatedRoute,
    private idle: Idle,
    private _joyride:JoyrideService
     ) {

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.getPageMeta();
      }
    });

    // sets an idle timeout in seconds.
    idle.setIdle(20 * 60);
    // idle.setIdle(0.2 * 60);

    // sets a timeout period of 5 seconds. after 20 minutes of inactivity, the user will be considered timed out.
    idle.setTimeout(5);

    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.'
      console.log(this.idleState);
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      // this.userIdleModal = true;

      this.modal.confirm({
        nzTitle: 'Snoozing......',
        nzClassName: 'app_warning_modal_main',
        nzContent: AppWarningModalComponent,
        nzComponentParams: {
          message: 'You have been Inactive for more than 20 minutes. Click on "Wake Up" to get back in now!',
          path: "../../assets/images/snoozing_img.gif"
        },
        nzOkText: this.isLoadingLogOut ? 'Wake Up...' : 'Wake Up',
        nzOkType: 'primary',
        nzOkDanger: true,
        nzCentered: true,
        nzCancelText: null,
        nzIconType: null,
        nzAutofocus: null,
        nzKeyboard:false,
        nzClosable:false,
        nzOnOk: () => {
          window.location.reload();
        }
      });

    });

    idle.onIdleStart.subscribe(() => {
        this.idleState = 'You\'ve gone idle!'
        console.log(this.idleState);
        // this.sharedService.endTour();


    });


    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!'
      console.log(this.idleState);
      setTimeout(() => {
        document.body.id = 'startSnozing';
      }, 4000);
    });
    this.reset();


    //socket
    this.socket = io(environment.SOCKET_ENDPOINT, {
      transports: ['websocket'],
      forceNew:true,
    });
    //console.log(this.socket);
    this.socket.connect();
  }

  // reset the user idel timer.
  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }


  ngOnInit(): void {
    this.sharedService.getWidth();
    this.sharedService.username = this.store.selectSnapshot(AuthState.user).name;
    this.sharedService.profile.next(this.store.selectSnapshot(AuthState.user).profile_pic);
    this.sharedService.email = this.store.selectSnapshot(AuthState.user).email;
    this.sharedService.need_tour = this.store.selectSnapshot(AuthState.user).need_tour;
    this.sharedService.currentSubscription = this.store.selectSnapshot(SubscriptionState).subscription;
    this.userId = this.store.selectSnapshot(AuthState.user).id;
    this.loggedUserRole = this.store.selectSnapshot(TeamState.role);
    let listOfPermissions = this.store.selectSnapshot(TeamState.permissions);
    this.isInvitePermission = this.helper.checkPermission(listOfPermissions, 'invite_team');
    this.isTeamUpdatePermission = this.helper.checkPermission(listOfPermissions, 'update_team');
    this.isTeamDeletePermission = this.helper.checkPermission(listOfPermissions, 'delete_team');
    this.isVisble = this.helper.checkPermission(listOfPermissions, 'create_team');
    this.permissions = this.store.selectSnapshot(TeamState.permissions);
    this.organizationForm = this.formBuilder.group({
      organization_name: [null],
    });
    this.sharedService.getCurrentPlan();

    if (this.store.selectSnapshot(SubscriptionState).subscription !== null ||
      (this.store.selectSnapshot(AuthState.user).free_user &&
        this.store.selectSnapshot(AuthState.user).free_user !== null) ||
      this.store.selectSnapshot(InAppPurchaseState).inAppPurchase !== null) {
        this.subscription = true;
    }
    this.needTeamTour = this.store.selectSnapshot(AuthState.user).need_team_tour;
    // this.needTeamTour = 1;

    this.getData();
    this.user = this.store.selectSnapshot(AuthState.user);

    this.allOrganizations = [...this.store.selectSnapshot(TeamState.organizations)];
    console.log("organization length",this.allOrganizations.length);



    if (localStorage.getItem('organization_id')) {

      var org_id = parseInt(localStorage.getItem('organization_id'));
      var selectedOrg = this.allOrganizations.filter((item)=>{ if(item.id === org_id){ return item; } });
      if(selectedOrg) {
        this.selectedOrganization = selectedOrg[0];
        this.selectedOrganizationModal = this.selectedOrganization;
      }

    } else {
      localStorage.setItem('organization_id', this.allOrganizations[0]?.id);
      console.log("setItem")
    }

    /*Notifications Count*/
    /*this.sharedService.getTopNotification();
    setInterval(() => {
      this.sharedService.getTopNotification();
    }, 60 * 1000);*/

    var teamList = this.sharedService.getTeamList();
    this.getAssignedPosts();

    console.log("layout OnInit need_tour",this.sharedService.need_tour);
    console.log("layout OnInit need_tour",typeof this.sharedService.need_tour);

    /*Notifications Count*/
    this.sharedService.getTopNotification();
    let team_id = parseInt(localStorage.getItem('team_id'));
    //socket
    this.socket.on("connect", () => {
      console.log('Layout Socket Successfully connected!');
      this.socket.emit("join","team_"+team_id)
      this.socket.on('new_top_notification', (res) => {
        console.log('socket new_top_notification', res);
        if(res === team_id){
          this.sharedService.getTopNotification();
        }
      });
    });
  }

  ngAfterViewInit() {
    console.log("layout ngAfterViewInit")
    if (localStorage.getItem('need_tour')) {
        this.sharedService.need_tour = parseInt(localStorage.getItem('need_tour'));
        console.log("layout ngAfterViewInit need_tour",this.sharedService.need_tour);
    }
    console.log("Need team tour",this.needTeamTour ,this.sharedService.need_tour, this.user.is_super_admin);
    if(this.needTeamTour == 1 && (this.sharedService.need_tour == 0 || !this.user.is_super_admin) && this.allOrganizations.length > 1){
      console.log("Organizaton tour called");
      this.startOrganizationTour()
    }
  }

  getPageMeta(){
    /* main page header */
    const rt = this.getChild(this.activatedRoute);
    rt.data.subscribe(data => {
      //this.pageName = data.name;
      if(data.name!=="Accounts" && data.name!=="Settings"){
        this.pageName = data.name;
      }else if((data.name==="Accounts" && data.name!=="Settings") && rt.url.value.length===0){
        this.pageName = data.name;
      }else{
        this.pageName = '';
      }
    });

    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        const rt = this.getChild(this.activatedRoute);
        //console.log("rt : ",rt);
        rt.data.subscribe(data => {
          //this.pageName = data.name;
          if(data.name!=="Accounts" && data.name!=="Settings"){
            this.pageName = data.name;
          }else if((data.name==="Accounts" && data.name!=="Settings") && rt.url.value.length===0){
            this.pageName = data.name;
          }else{
            this.pageName = '';
          }
        });
      });
  }

  // @HostListener("click") onClick(){
  //   console.log("User Click using Host Listener")
  //   if(this.isOpenMyAccount){
  //     this.isOpenMyAccount = true;
  //   }
  // }

  // @HostListener('document:click', ['$event']) onDocumentClick(event) {
  //   console.log("event log",event);

  // }



  // docClick(){
  //   console.log("Doc Clicked");
  // }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }



  async getData() {
    this.sharedService.showLoading();
    this.organizations = await this.getOrganizations();
    this.superAdminOrg = await this.getSuperAdminOrg();
    this.sharedService.hideLoading();

  }

  getTeams() {
    return new Promise(resolve => {
      this.teamService.getTeamList().subscribe((res) => {
        resolve(res.data);
      }, error => {
        resolve([]);
      });
    });
  }

  getSuperAdminOrg() {
    return new Promise(resolve => {
      this.teamService.getSuperAdminOrg().subscribe((res) => {
        if (res.data.length > 0) {
          this.isOrganizations = true;
        } else {
          this.isOrganizations = false;
        }
        resolve(res.data);
      }, error => {
        resolve([]);
      });
    });
  }

  getOrganizations() {
    return new Promise(resolve => {
      this.teamService.getOrganizationList().subscribe((res) => {
        if (res.data.length > 0) {
          this.isOrganizations = true;
        } else {
          this.isOrganizations = false;
        }
        resolve(res.data);
      }, error => {
        resolve([]);
      });
    });
  }

  signOut(): void {
    if (this.sharedService.isMobile) {
      this.isCollapsed = true;
    }
    this.modal.confirm({
      nzTitle: 'Log Out?',
      nzClassName: 'app_warning_modal_main',
      nzContent: AppWarningModalComponent,
      nzComponentParams: {
        message: "Are you sure you want to Log Out?",
      },
      nzOkText: this.isLoadingLogOut ? 'Log Outing...' : 'Yes, Log Out',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzCentered: true,
      nzCancelText: 'Cancel',
      nzIconType: null,
      nzAutofocus: null,
      nzOkDisabled: this.isLoadingLogOut,
      nzCancelDisabled: this.isLoadingLogOut,
      nzOnOk: () => {
        this.isLoadingLogOut = true;
        this.authService.logout().subscribe((res) => {
          this.isLoadingLogOut = false;
          if (res.code === 200) {
            this.router.navigate(['/', 'auth', 'login']);
            //localStorage.clear();
            this.sharedService.setRememberMe();
            this.store.dispatch(new Logout());
            this.store.dispatch(new SubscriptionClear());
            if (res.toast === true) {
              this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, this.sharedService.defaultMessageSuccess, res.message);
            }
          }
        }, error => { });
      }
    });
  }

  changePage() {
    if (this.sharedService.isMobile) {
      this.isCollapsed = true;
    }
  }

  beforeUpload = (file: NzUploadFile, _fileList: NzUploadFile[]): boolean => {

    if (file.type !== 'image/jpeg' && file.type !== 'image/jpg' && file.type !== 'image/png' && file.type !== 'image/svg' && file.type !== 'image/svg+xml') {
      this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Media files', 'You can upload only image file');
      return false;
    }

    if (file.size > 2 * 1024 * 1024) {
      this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Media files', 'You can\'t upload greater then 2 MB image file');
      return false;
    }
    this.loading = true;
    this.icon = file;

    this.storeFile();
    return true;
  }

  async storeFile(): Promise<void> {
    const folder = 'team/team-icon-' + this.userId.id + '/';
    let fileName = moment().unix() + '-' + this.icon?.name;
    fileName = fileName.split(' ').join('_');
    const data = await this.wasabiService.uploadMedia(this.icon, folder, fileName);

    if (data) {
      this.iconUrl = folder + fileName;
      this.deleteIcon = false;
    }
    this.loading = false;
  }

  setNotificationCount(): void {
    if (this.sharedService.notificationCount > 0) {
      this.userId.Service.setNotificationCount().subscribe(res => {
        if (res.code === 200) {
          if (res.data) {
            this.sharedService.notificationCount = 0;
          }
        }
      });
    }
  }

  goToPage(notification): void {

    this.markAsRead(notification.id);

    var page = JSON.parse(notification.data);
    if (page.extra !== undefined) {
      let extra = JSON.parse(page.extra);
      if (extra.type == "post") {
        this.router.navigate([extra.link], { queryParams: { post: extra.post } });
      } else {
        this.router.navigate([extra.link], { queryParams: extra.post });
      }
      this.sharedService.getTopNotification();
    } else {
      this.router.navigate(['/', page.link]);
    }
  }

  markAsRead(notification_id) {
    var notification_ids = [];
    if(notification_id==='') {
      this.notifications.filter((item) => {
        if (item.is_checked) {
          notification_ids.push(item.id);
        }
      });

      if (notification_ids.length == 0) {
        this.sharedService.displayNotification(this.sharedService.messageTypeInfo, this.sharedService.defaultMessageError, 'please select any notification');
        return;
      }
    }else{
      notification_ids.push(notification_id);
    }

    var data = {
      notification_ids
    };
    this.userService.markAsReadNotification(data).subscribe(res => {
      if (res.code === 200) {
        this.sharedService.getTopNotification();
      }
    });
  }

  getNotificationText(data, type) {
    data = JSON.parse(data);
    if (type == 'text') {
      return data.text;
    } else {
      return data.icon;
    }

  }

  getHumanReadableTime(date): string {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return moment(date).tz(timeZone).fromNow();
  }

  deleteImage(): void {
    this.iconUrl = null;
    this.icon = null;
    if (this.userId.profile_pic !== null && this.userId.profile_pic !== '') {
      this.deleteIcon = true;
    }
  }

  SortModal(): void {
    this.visible = true;
    

  }

  closeOrganisation(event) {
    this.spinnerService.show();
    localStorage.setItem('organization_id', this.selectedOrganizationModal?.id);
    localStorage.setItem('team_id', this.selectedOrganizationModal?.default_team_id);
    this.authService.getOrganisationSubscription().subscribe(res => {
      if(res.code == 200){
        this._joyride?.closeTour();
        this.store.dispatch(new SubscriptionGet({
            subscription: res.data.subscription,
            subscriptionCancelAtOnce: res.data.subscriptionCancelAtOnce
        }));
      }
      this.visible = false;
      this.router.navigate(['/']);
      setTimeout(()=>{
        window.location.reload();
      },500);
    });
  }

  closeOrgModel() {
    this.visible = false;
  }

  showOrganizationModal(organization: Object) {
    if (Object.keys(organization).length > 0) {
      this.modalTitle = 'Edit Organization';
      this.iconUrl = organization['icon'];
      this.editOrganizationId = Number(organization['id']);
      this.organizationForm?.patchValue({
        organization_name: organization['organization_name']
      });
    }
    this.organization_modal_preview = true;
  }

  closeOrganization() {
    this.editOrganizationId = 0;
    this.organizationForm.reset();
    this.organization_modal_preview = false;

  }
  isSaveOrg = false;
  saveOrganization() {
    for (const i in this.organizationForm.controls) {
      this.organizationForm.controls[i].markAsDirty();
      this.organizationForm.controls[i].updateValueAndValidity();
    }

    if (this.organizationForm?.valid) {
      const value = this.organizationForm?.value;
    }

    if (this.organizationForm?.valid) {
      const value = this.organizationForm?.value;
      const postValue: any = HelperService.createFormData(value);
      if (this.icon !== null) {
        postValue.append('icon', this.iconUrl);
      }
      if (this.deleteIcon) {
        postValue.append('delete_icon', '1');
      }
      if (this.editOrganizationId > 0) {
        postValue.append('organization_id', this.editOrganizationId);
      }

      this.spinnerService.show();
      this.isSaveOrg = true;
      this.teamService.saveOrganizationData(postValue).subscribe((res) => {

        if (res.code == 200) {

          this.organization_modal_preview = false;
          this.visible = false;
          this.organizationForm.reset();

          this.iconUrl = null;
          this.getData();
          this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, this.sharedService.defaultMessageSuccess, res.message);
          this.isSaveOrg = false;

          setTimeout(() => {
            window.location.reload();
          }, 2000);

        } else {
          this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, res.message);
          this.isSaveOrg = false;
        }
      }, error => {
        this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, Constants.ERROR_MSG);
        this.organization_modal_preview = false;
        this.isSaveOrg = false;
      });

      // window.location.reload();
      this.spinnerService.hide();
    }

    this.editOrganizationId = 0;
  }


  leaveOrganization(member_id, org_id) {
    this.deleteOrgvisible = true;
    this.member_org_id = member_id;
    this.team_org_id = org_id
  }

  deleteOrgModalClose() {
    this.deleteOrgvisible = false;
  }

  deleteOrg(m_id, t_id) {
    this.isDisableOrg = true;
    this.teamService.deleteMember(m_id, t_id, 1).subscribe(res => {
      this.spinnerService.hide();
      if (res.code === 200) {
        this.isDisableOrg = true;
        this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, this.sharedService.defaultMessageSuccess, 'Organization Leave Successfully!');

        // Logout after leave organization
        localStorage.clear();
        this.store.dispatch(new Logout());
        this.store.dispatch(new SubscriptionClear());
        this.router.navigate(['/', 'auth', 'login']);
      } else {
        this.isDisableOrg = false;
        this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, res.message);
      }
    });
  }

  checkSubscription() {
    let plan = null;
    if (this.store.selectSnapshot(AuthState.user).free_user && this.store.selectSnapshot(AuthState.user).free_user !== null) {
      plan = this.store.selectSnapshot(AuthState.user).free_user?.plan_id;
    } else {
      if (this.store.selectSnapshot(SubscriptionState).subscription !== null) {
        plan = this.store.selectSnapshot(SubscriptionState).subscription?.plan_id;
      }
      else {
        if (this.store.selectSnapshot(InAppPurchaseState).inAppPurchase !== null) {
          plan = this.store.selectSnapshot(InAppPurchaseState).inAppPurchase?.plan_id;
        }
      }
    }
    return plan;
  }

  goToBulkSchedule(): void {
    const plan = this.checkSubscription();
    if (plan === null) {
      return;
    }
    /*if (plan === 'plan_1') {
      const modal = this.modal.error({
        nzClassName: 'schedule_access_main',
        nzTitle: 'Can\'t Access Bulk schedule',
        nzContent: 'To access Bulk schedule, you need to upgrade to Professional or Advanced membership.',
        nzOnOk: () => {
          if (this.store.selectSnapshot(AuthState.user).free_user !== null) {
            modal.destroy();
          } else {
            this.router.navigate(['/', 'subscription']);
          }
        }
      });
    } else {
      this.router.navigate(['/', 'bulk-schedule']);
    }*/
    this.router.navigate(['/', 'bulk-schedule']);
  }

  onResize(event) {
    this.sharedService.getWidth();
    this.isMobile = this.sharedService.isMobile;
  }

  resetOpenModals(type){
    if(type=="notification"){
      this.isOpenMyAccount = false;
      this.isOpenMywork = false;
    }else if(type=="account"){
      this.isOpenMyNotification = false;
      this.isOpenMywork = false;
    }else if(type=="my_work"){
      this.isOpenMyAccount = false;
      this.isOpenMyNotification = false;
    }
  }

  openNotification(){
    this.resetOpenModals("notification");
    this.isOpenMyNotification = !this.isOpenMyNotification;
  }



  // @HostListener('document:click', ['$event']) onDocumentClick(event) {
  //   this.openMyAccount(2,event);
  //   event.stopPropagation()
  // }


  openMyAccount = () => {
    this.resetOpenModals("account");
    this.isOpenMyAccount = !this.isOpenMyAccount;
    // if(id == 1) {
    //   this.isOpenMyAccount = true;
    //   event.stopPropagation()
    // } else {
    //   this.isOpenMyAccount = false;
    // }
  }

  openMyWork = () => {
    this.resetOpenModals("my_work");
    this.isOpenMywork = !this.isOpenMywork;
  }

  getAssignedPosts() {
    this.teamService.getAssignedPosts().subscribe((res) => {
      //console.log("getAssignedPosts : ",res.data);
        if (res.data) {
            let data = res.data;
            this.assignedPosts = data?.assigned;
            this.recentPosts = data?.recent;
          }
        }, error => {
    });
  }

  changeWorkIndex(index) {
    this.selectedTab = index;
  }

  goToYourPost(){
    if(this.selectedTab===1){
      this.router.navigate(['/post/manage-post'], {
        queryParams: { post: 'delivered-post' },
      });
    }else{
      this.router.navigate(['/post/manage-post'], {
        queryParams: { post: 'draft-post' },
      });
    }
  }

  onDropdownVisibleChange(): void {
    console.log("onDropdownVisibleChange : ")
    this.isAccountDropdownOpen = true;
  }

  startOrganizationTour(){
    console.log("Organization tour ",this.needTeamTour);
    this._joyride.startTour({
      steps: ['org_s1'],
      stepDefaultPosition: 'right',
    }).subscribe({
      next: (step) => {
          console.log("organization tour ", step);
          document.body.id = step.name;
      },
      error: (error) => {
          console.log(error);
      },
      complete: () => {
          console.log("Organization tour ended");
          this.endTeamTour(1);
          document.querySelector('body').removeAttribute('id');
      }
    });
  }

  endTeamTour(val){
    this._joyride.closeTour();
    this.needTeamTour = val;
    this.userService.endTeamTour().subscribe(res => {
      if (res.code === 200) {
          document.body.classList.toggle('isTourActive');
      }
    }, error => {
      console.error('endTeamTour :',error)
    });
  }

  isOrgPopoverOpen(){
    this.isOrgPopover = true;
    this._joyride?.closeTour();
    console.log("Need team tour",this.needTeamTour ,this.sharedService.need_tour, this.user.is_super_admin);
    
    if(this.needTeamTour == 1 && (this.sharedService.need_tour == 0 || !this.user.is_super_admin) && this.allOrganizations.length > 1){
      setTimeout(() => {
        document.body.classList.add('no-scroll');
        this._joyride?.startTour({
          steps: ['org_s2'],
          stepDefaultPosition: 'right',
        }).subscribe((step) => {
          // this.isTeamTourActive = true;
          console.log("Start organization tour ",step);
          document.body.id = step.name;
        },(error) => {
          console.log(error);
        },() => {
          this.endTeamTour(0);
          document.querySelector('body').removeAttribute('id');
          document.body.classList.remove('no-scroll');
      });
      }, 500);
    }
  }
  // isVisible = false;
  // handleVisibleChange(event): void {
  //   console.log(event,"event");
  //   this.isVisble = true;
  // }

}