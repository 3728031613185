<nz-tabset class="tabset_default my_work_tabset" [nzSelectedIndex]="selectedTab" (nzSelectedIndexChange)="tabChanged($event)">
    <nz-tab nzTitle="Assigned to me">
        <ng-container *ngIf="assignedPosts.length != 0; else noData">
            <div class="my_work_list" nz-menu>
                <div class="my_work_list_in" nz-menu-item *ngFor="let post of assignedPosts">
                    <div class="my_work_list_left">
                        <div class="img_found" *ngIf="post.post_gallery.length > 0">
                            <app-image-preview
                                [class]="post.post_gallery[0].file_type ==='video' ? 'manage-post-video':''"
                                [isVideo]="post.post_gallery[0].file_type ==='video'"
                                [imageUrl]="post.post_gallery[0].media_file">
                            </app-image-preview>
                        </div>
                        <div class="img_not_found" *ngIf="post.post_gallery.length === 0">
                            <img src="../../assets/images/placeholder_image.png" alt="">
                        </div>
                    </div>
                    <div class="my_work_list_right">
                        <div class="my_work_top">
                            <div class="my_work_top_left">
                                <app-provider [className]="'social-icon'" [iconSize]="sharedService.mediumIcon" [provider]="post.provider"></app-provider>
                                <span>Post</span>
                            </div>
                            <div class="my_work_top_right">{{post.post_time * 1000 | dateConvertPipe}}</div>
                        </div>
                        <div class="my_work_title">
                            <h5>{{post.content}}</h5>
                        </div>
                        <div class="my_work_status">
                            <span>{{post.post_status | titlecase}}</span>
                            <span>
                                <img [src]="'../../../assets/images/'+sharedService.priorities[post?.priority-1]?.image"
                                    alt="">
                                {{sharedService.priorities[post?.priority-1]?.name}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template #noData>
            <div class="custome_default_msg">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
                    xmlns:svgjs="http://svgjs.com/svgjs" width="512" height="512" x="0" y="0" viewBox="0 0 64 64"
                    style="enable-background:new 0 0 512 512" xml:space="preserve" class="">
                    <g>
                        <circle cx="23" cy="25" r="20" fill="#e9e9e9" data-original="#ebedf0" class=""></circle>
                        <path fill="#ffffff"
                            d="M61 9v16c0 1.1-.9 2-2 2h-2l-7 7v-7H37c-1.1 0-2-.9-2-2V9c0-1.1.9-2 2-2h22c1.1 0 2 .9 2 2z"
                            data-original="#ffffff" class=""></path>
                        <path fill="#afddfb"
                            d="m31.75 51.5 4.7-9.4c.33-.67 1.03-1.1 1.78-1.1h19.96a.51.51 0 0 1 .45.73l-7.09 14.16A2 2 0 0 1 49.76 57H29l2-4zM27 47v6h-7c0-1.68-1.36-3-3.04-3H9v-3H3v-4c0-4.42 3.58-8 8-8h1c.85 2.39 3.27 4 6 4s5.15-1.61 6-4c4.42 0 8 3.58 8 8v4h-1.88z"
                            data-original="#8ff3ad" class=""></path>
                        <path fill="#ffffff" d="M31.75 47v4.5L31 53h-4v-6h3.12z" data-original="#ffffff" class="">
                        </path>
                        <path fill="#afddfb"
                            d="m31 53-2 4H16c-1.1 0-2-.9-2-2v-2H30.99zM24 15s2 1.42 2 4-2.08 4.1-2.08 4.1c-.773-1.597-2.138-2.85-3.92-2.85-2.71 0-4 2.75-4 2.75-3.44-.56-3.55 3.38-2 4 0 0-2 0-2-5 0-5.94 6.92-6 8-6 3.12 0 4-1 4-1z"
                            data-original="#8ff3ad" class=""></path>
                        <path fill="#ffffff"
                            d="M24 24v3c0 2.76-2.24 5-5 5s-5-2.24-5-5c-1.55-.62-1.44-4.56 2-4 0 0 1.29-2.75 4-2.75 1.782 0 3.146 1.253 3.92 2.85.05.29.08.59.08.9zM20 53h-6v2H8c-2.21 0-4-1.79-4-4v-4h5v3h7.96c1.68 0 3.04 1.32 3.04 3z"
                            data-original="#ffffff" class=""></path>
                        <path fill="#262c6c"
                            d="M49.76 57.75H29a.752.752 0 0 1-.638-.355.753.753 0 0 1-.033-.73l7.45-14.9a2.748 2.748 0 0 1 2.451-1.515h19.96a1.26 1.26 0 0 1 1.138 1.78L52.22 56.226a2.737 2.737 0 0 1-2.46 1.524zm-19.547-1.5H49.76c.476 0 .905-.267 1.119-.695l6.913-13.805H38.23a1.24 1.24 0 0 0-1.107.681zM19 32.75c-3.17 0-5.75-2.58-5.75-5.75a.75.75 0 0 1 1.5 0c0 2.343 1.907 4.25 4.25 4.25s4.25-1.907 4.25-4.25v-3c0-.251-.022-.504-.069-.772-.154-.888 1.569-1.768 1.569.772v3c0 3.17-2.58 5.75-5.75 5.75zM14 55.75H8A4.756 4.756 0 0 1 3.25 51v-4a.75.75 0 0 1 1.5 0v4A3.254 3.254 0 0 0 8 54.25h6a.75.75 0 0 1 0 1.5z"
                            data-original="#303e58" class=""></path>
                        <path fill="#262c6c"
                            d="M20 53.75a.75.75 0 0 1-.75-.75c0-1.262-1.006-2.25-2.29-2.25H9a.75.75 0 0 1-.75-.75v-3a.75.75 0 0 1 1.5 0v2.25h7.21c2.09 0 3.79 1.682 3.79 3.75a.75.75 0 0 1-.75.75zM30.12 47.75H26a.75.75 0 0 1-.75-.75v-4a.75.75 0 0 1 1.5 0v3.25h3.37a.75.75 0 0 1 0 1.5z"
                            data-original="#303e58" class=""></path>
                        <path fill="#262c6c"
                            d="M10 47.75H3a.75.75 0 0 1-.75-.75v-4c0-4.825 3.925-8.75 8.75-8.75h1c.317 0 .6.2.707.499.745 2.094 2.872 3.501 5.293 3.501s4.548-1.407 5.293-3.501A.752.752 0 0 1 24 34.25c4.825 0 8.75 3.925 8.75 8.75a.75.75 0 0 1-1.5 0c0-3.831-2.986-6.977-6.752-7.233C23.396 38.17 20.855 39.75 18 39.75c-2.862 0-5.408-1.587-6.505-4H11c-3.998 0-7.25 3.252-7.25 7.25v3.25h5.5V43a.75.75 0 0 1 1.5 0v4a.75.75 0 0 1-.75.75zM14 27.75c-.281 0-2.75-.161-2.75-5.75 0-6.263 6.697-6.75 8.75-6.75 2.638 0 3.448-.759 3.457-.766.258-.253.683-.305.978-.095.095.067 2.316 1.678 2.316 4.611 0 2.927-2.29 4.634-2.388 4.706a.75.75 0 0 1-1.149-.355C22.97 22.853 21.936 21 20 21c-2.208 0-3.31 2.295-3.321 2.318a.75.75 0 0 1-.8.422c-1.074-.175-1.794.176-2.021.991-.195.7.07 1.433.42 1.572A.75.75 0 0 1 14 27.75zm9.956-11.793c-.641.363-1.845.793-3.956.793-2.18 0-7.25.512-7.25 5.25 0 .546.025 1.025.069 1.446.508-.752 1.414-1.315 2.771-1.245.556-.891 1.984-2.701 4.41-2.701 2.099 0 3.423 1.406 4.095 2.395.512-.591 1.155-1.593 1.155-2.895 0-1.501-.795-2.54-1.294-3.043zM29 57.75H16A2.752 2.752 0 0 1 13.25 55v-2a.75.75 0 0 1 .75-.75h13a.75.75 0 0 1 0 1.5H14.75V55c0 .689.561 1.25 1.25 1.25h13c.992 0 .993 1.5 0 1.5zM27 50.75a.75.75 0 0 1-.75-.75v-3a.75.75 0 0 1 1.5 0v3a.75.75 0 0 1-.75.75z"
                            data-original="#303e58" class=""></path>
                        <path fill="#262c6c"
                            d="M49.25 34v-6.25H37A2.752 2.752 0 0 1 34.25 25V9A2.752 2.752 0 0 1 37 6.25h22A2.752 2.752 0 0 1 61.75 9v16A2.752 2.752 0 0 1 59 27.75h-1.689l-6.78 6.78c-.471.471-1.281.136-1.281-.53zM37 7.75c-.689 0-1.25.561-1.25 1.25v16c0 .689.561 1.25 1.25 1.25h13a.75.75 0 0 1 .75.75v5.189l5.72-5.72a.75.75 0 0 1 .53-.22h2c.689 0 1.25-.561 1.25-1.25V9c0-.689-.561-1.25-1.25-1.25z"
                            data-original="#979faf" class=""></path>
                        <path fill="#262c6c"
                            d="M48 12.75h-8a.75.75 0 0 1 0-1.5h8a.75.75 0 0 1 0 1.5zM56 12.75h-4a.75.75 0 0 1 0-1.5h4a.75.75 0 0 1 0 1.5zM43 17.75h-3a.75.75 0 0 1 0-1.5h3a.75.75 0 0 1 0 1.5zM56 17.75h-9a.75.75 0 0 1 0-1.5h9a.75.75 0 0 1 0 1.5zM49 22.75h-9a.75.75 0 0 1 0-1.5h9a.75.75 0 0 1 0 1.5zM56 22.75h-3a.75.75 0 0 1 0-1.5h3a.75.75 0 0 1 0 1.5z"
                            data-original="#979faf" class=""></path>
                    </g>
                </svg>
                <span>No<strong>#My work</strong>Yet!</span>
            </div>
        </ng-template>
    </nz-tab>
    <nz-tab nzTitle="Recent">
        <ng-container *ngIf="recentPosts.length != 0; else noData">
            <div class="my_work_list" nz-menu>
                <div class="my_work_list_in" nz-menu-item *ngFor="let post of recentPosts">
                    <div class="my_work_list_left">
                        <div class="img_found" *ngIf="post.post_gallery.length > 0">
                            <app-image-preview
                                [class]="post.post_gallery[0].file_type ==='video' ? 'manage-post-video':''"
                                [isVideo]="post.post_gallery[0].file_type ==='video'"
                                [imageUrl]="post.post_gallery[0].media_file">
                            </app-image-preview>
                        </div>

                        <div class="img_not_found" *ngIf="post.post_gallery.length === 0">
                            <img src="../../assets/images/placeholder_image.png" alt="">
                        </div>

                    </div>
                    <div class="my_work_list_right">
                        <div class="my_work_top">
                            <div class="my_work_top_left">
                                <app-provider [className]="'social-icon'" [iconSize]="sharedService.mediumIcon" [provider]="post.provider"></app-provider>
                                <span>Post</span>
                            </div>
                            <div class="my_work_top_right">{{post.post_time * 1000 | dateConvertPipe}}</div>
                        </div>
                        <div class="my_work_title">
                            <h5>{{post.content}}</h5>
                        </div>
                        <div class="my_work_status">
                            <span>{{post.post_status | titlecase }}</span>
                            <span>
                                <img [src]="'../../../assets/images/'+sharedService.priorities[post?.priority-1]?.image"
                                    alt="">
                                {{sharedService.priorities[post?.priority-1]?.name}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template #noData>
            <div class="custome_default_msg">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
                    xmlns:svgjs="http://svgjs.com/svgjs" width="512" height="512" x="0" y="0" viewBox="0 0 64 64"
                    style="enable-background:new 0 0 512 512" xml:space="preserve" class="">
                    <g>
                        <circle cx="23" cy="25" r="20" fill="#e9e9e9" data-original="#ebedf0" class=""></circle>
                        <path fill="#ffffff"
                            d="M61 9v16c0 1.1-.9 2-2 2h-2l-7 7v-7H37c-1.1 0-2-.9-2-2V9c0-1.1.9-2 2-2h22c1.1 0 2 .9 2 2z"
                            data-original="#ffffff" class=""></path>
                        <path fill="#afddfb"
                            d="m31.75 51.5 4.7-9.4c.33-.67 1.03-1.1 1.78-1.1h19.96a.51.51 0 0 1 .45.73l-7.09 14.16A2 2 0 0 1 49.76 57H29l2-4zM27 47v6h-7c0-1.68-1.36-3-3.04-3H9v-3H3v-4c0-4.42 3.58-8 8-8h1c.85 2.39 3.27 4 6 4s5.15-1.61 6-4c4.42 0 8 3.58 8 8v4h-1.88z"
                            data-original="#8ff3ad" class=""></path>
                        <path fill="#ffffff" d="M31.75 47v4.5L31 53h-4v-6h3.12z" data-original="#ffffff" class="">
                        </path>
                        <path fill="#afddfb"
                            d="m31 53-2 4H16c-1.1 0-2-.9-2-2v-2H30.99zM24 15s2 1.42 2 4-2.08 4.1-2.08 4.1c-.773-1.597-2.138-2.85-3.92-2.85-2.71 0-4 2.75-4 2.75-3.44-.56-3.55 3.38-2 4 0 0-2 0-2-5 0-5.94 6.92-6 8-6 3.12 0 4-1 4-1z"
                            data-original="#8ff3ad" class=""></path>
                        <path fill="#ffffff"
                            d="M24 24v3c0 2.76-2.24 5-5 5s-5-2.24-5-5c-1.55-.62-1.44-4.56 2-4 0 0 1.29-2.75 4-2.75 1.782 0 3.146 1.253 3.92 2.85.05.29.08.59.08.9zM20 53h-6v2H8c-2.21 0-4-1.79-4-4v-4h5v3h7.96c1.68 0 3.04 1.32 3.04 3z"
                            data-original="#ffffff" class=""></path>
                        <path fill="#262c6c"
                            d="M49.76 57.75H29a.752.752 0 0 1-.638-.355.753.753 0 0 1-.033-.73l7.45-14.9a2.748 2.748 0 0 1 2.451-1.515h19.96a1.26 1.26 0 0 1 1.138 1.78L52.22 56.226a2.737 2.737 0 0 1-2.46 1.524zm-19.547-1.5H49.76c.476 0 .905-.267 1.119-.695l6.913-13.805H38.23a1.24 1.24 0 0 0-1.107.681zM19 32.75c-3.17 0-5.75-2.58-5.75-5.75a.75.75 0 0 1 1.5 0c0 2.343 1.907 4.25 4.25 4.25s4.25-1.907 4.25-4.25v-3c0-.251-.022-.504-.069-.772-.154-.888 1.569-1.768 1.569.772v3c0 3.17-2.58 5.75-5.75 5.75zM14 55.75H8A4.756 4.756 0 0 1 3.25 51v-4a.75.75 0 0 1 1.5 0v4A3.254 3.254 0 0 0 8 54.25h6a.75.75 0 0 1 0 1.5z"
                            data-original="#303e58" class=""></path>
                        <path fill="#262c6c"
                            d="M20 53.75a.75.75 0 0 1-.75-.75c0-1.262-1.006-2.25-2.29-2.25H9a.75.75 0 0 1-.75-.75v-3a.75.75 0 0 1 1.5 0v2.25h7.21c2.09 0 3.79 1.682 3.79 3.75a.75.75 0 0 1-.75.75zM30.12 47.75H26a.75.75 0 0 1-.75-.75v-4a.75.75 0 0 1 1.5 0v3.25h3.37a.75.75 0 0 1 0 1.5z"
                            data-original="#303e58" class=""></path>
                        <path fill="#262c6c"
                            d="M10 47.75H3a.75.75 0 0 1-.75-.75v-4c0-4.825 3.925-8.75 8.75-8.75h1c.317 0 .6.2.707.499.745 2.094 2.872 3.501 5.293 3.501s4.548-1.407 5.293-3.501A.752.752 0 0 1 24 34.25c4.825 0 8.75 3.925 8.75 8.75a.75.75 0 0 1-1.5 0c0-3.831-2.986-6.977-6.752-7.233C23.396 38.17 20.855 39.75 18 39.75c-2.862 0-5.408-1.587-6.505-4H11c-3.998 0-7.25 3.252-7.25 7.25v3.25h5.5V43a.75.75 0 0 1 1.5 0v4a.75.75 0 0 1-.75.75zM14 27.75c-.281 0-2.75-.161-2.75-5.75 0-6.263 6.697-6.75 8.75-6.75 2.638 0 3.448-.759 3.457-.766.258-.253.683-.305.978-.095.095.067 2.316 1.678 2.316 4.611 0 2.927-2.29 4.634-2.388 4.706a.75.75 0 0 1-1.149-.355C22.97 22.853 21.936 21 20 21c-2.208 0-3.31 2.295-3.321 2.318a.75.75 0 0 1-.8.422c-1.074-.175-1.794.176-2.021.991-.195.7.07 1.433.42 1.572A.75.75 0 0 1 14 27.75zm9.956-11.793c-.641.363-1.845.793-3.956.793-2.18 0-7.25.512-7.25 5.25 0 .546.025 1.025.069 1.446.508-.752 1.414-1.315 2.771-1.245.556-.891 1.984-2.701 4.41-2.701 2.099 0 3.423 1.406 4.095 2.395.512-.591 1.155-1.593 1.155-2.895 0-1.501-.795-2.54-1.294-3.043zM29 57.75H16A2.752 2.752 0 0 1 13.25 55v-2a.75.75 0 0 1 .75-.75h13a.75.75 0 0 1 0 1.5H14.75V55c0 .689.561 1.25 1.25 1.25h13c.992 0 .993 1.5 0 1.5zM27 50.75a.75.75 0 0 1-.75-.75v-3a.75.75 0 0 1 1.5 0v3a.75.75 0 0 1-.75.75z"
                            data-original="#303e58" class=""></path>
                        <path fill="#262c6c"
                            d="M49.25 34v-6.25H37A2.752 2.752 0 0 1 34.25 25V9A2.752 2.752 0 0 1 37 6.25h22A2.752 2.752 0 0 1 61.75 9v16A2.752 2.752 0 0 1 59 27.75h-1.689l-6.78 6.78c-.471.471-1.281.136-1.281-.53zM37 7.75c-.689 0-1.25.561-1.25 1.25v16c0 .689.561 1.25 1.25 1.25h13a.75.75 0 0 1 .75.75v5.189l5.72-5.72a.75.75 0 0 1 .53-.22h2c.689 0 1.25-.561 1.25-1.25V9c0-.689-.561-1.25-1.25-1.25z"
                            data-original="#979faf" class=""></path>
                        <path fill="#262c6c"
                            d="M48 12.75h-8a.75.75 0 0 1 0-1.5h8a.75.75 0 0 1 0 1.5zM56 12.75h-4a.75.75 0 0 1 0-1.5h4a.75.75 0 0 1 0 1.5zM43 17.75h-3a.75.75 0 0 1 0-1.5h3a.75.75 0 0 1 0 1.5zM56 17.75h-9a.75.75 0 0 1 0-1.5h9a.75.75 0 0 1 0 1.5zM49 22.75h-9a.75.75 0 0 1 0-1.5h9a.75.75 0 0 1 0 1.5zM56 22.75h-3a.75.75 0 0 1 0-1.5h3a.75.75 0 0 1 0 1.5z"
                            data-original="#979faf" class=""></path>
                    </g>
                </svg>
                <span>No<strong>#My work</strong>Yet!</span>
            </div>
        </ng-template>
    </nz-tab>
</nz-tabset>