<ng-container *ngIf="type==='account_connect' || type==='connect_instagram'">

  <div class="con_title" *ngIf="platform===sharedService.facebookProvider">
    <h5>Which Facebook Page do you want to add?</h5>
    <p>Choose the Facebook pages that you would like to add to {{sharedService.appName}}</p>
  </div>

  <div class="con_title" *ngIf="platform===sharedService.instagramProvider">
    <h5>Which Instagram account do you want to add?</h5>
    <p>Choose the Instagram account that you would like to add to {{sharedService.appName}}</p>
  </div>

  <div class="con_title" *ngIf="platform===sharedService.linkedinProvider">
    <h5>Which LinkedIn account do you want to add?</h5>
    <p>Choose the LinkedIn account that you would like to add to {{sharedService.appName}}</p>
  </div>

  <div class="search_account">
    <label>Search by name or keyword</label>
    <nz-input-group [nzPrefix]="suffixIconSearch">
      <input type="text" nz-input placeholder="Search..."
             [(ngModel)]="accountSearchText" (input)="accountSearch()" />
    </nz-input-group>
    <ng-template #suffixIconSearch><span nz-icon nzType="search"></span></ng-template>
  </div>

  <!--<div class="pages_title_main">
    <h5>Facebook Pages</h5>
    <p>You need to be a Page Admin or Editor for any Pages you want to add to One For All Social.</p>
  </div>-->
</ng-container>

<label *ngIf="(type!=='account_connect' && type!=='connect_instagram') && accounts.length > 0 && allDisabledAccounts.length!==accounts.length"
       [disabled]="accounts.length === 1"
       class="filter_select_all_account"
       nz-checkbox [(ngModel)]="allChecked"
       (ngModelChange)="updateAllChecked()">
  Select All Platforms
</label>

<perfect-scrollbar style="max-height: calc(100vh - 530px);">
  <div class="account_list" *ngIf="accounts.length > 0">
    <ng-container *ngFor="let account of (accountSearchText!=='' ? filteredAccounts : accounts); let i =index">
      <div class="account_list_in">
        <!--{{account | json}}-->

        <div class="account_flag" *ngIf="type==='connect_instagram'">
          <img src="../../../assets/images/facebook_flag.png" alt="">
          <span>{{account?.page_name}}</span>
        </div>

        <label nz-checkbox [(ngModel)]="account.is_checked" [nzDisabled]="account.disabled"
               [class.disabled]="account.disabled" [class.selectConnection]="platform !== ''" (ngModelChange)="updateSingleChecked(account)" >
          <!-- nz-tooltip [nzTooltipTitle]="account.disabled?'Account is already connected' : ''" -->
          <div class="account_list_info_main">
            <div class="account_list_info" >
              <div class="img_sec">
                <app-image-preview [isVideo]="false" [imageUrl]="account.image" [class]="'account-list-image'">
                </app-image-preview>
                <app-provider [iconSize]="sharedService.mediumIcon" [className]="'select_account_social_icon'" [provider]="account.provider"></app-provider>
              </div>
              <div class="name_wrp">
                <span>{{account.name}}</span>
                <!--permission-->
                <span *ngIf="(account.provider === sharedService.instagramProvider ||
                account.provider === sharedService.facebookProvider) &&
                account.not_have_permission !== undefined && account.not_have_permission.length > 0">
                  <i nz-icon nzType="info-circle" nzTheme="fill" class="margin-left-2"
                     (click)="openPermissionModel(account)"></i>
                </span>
                <span class="account_type" *ngIf="type === 'bulk_post'">{{account.type | titlecase}}</span>

              </div>
            </div>

            <ng-container *ngIf="platform !== ''">
              <ng-container *ngIf="account?.type!=='GROUP'">
                <div class="con_label"
                     [class.isSelected]="account.is_checked"
                     [class.Notdisabled]="!account.disabled"
                     [class.disabled]="account.disabled">
                  <span>{{ account.is_checked?'Selected': account.disabled?'Connected':'Connect' }}</span>
                </div>
              </ng-container>
              <ng-container *ngIf="account.type==='GROUP'">
                <div *ngIf="!account.app_install_link"
                     class="con_label"
                     [class.isSelected]="account.is_checked"
                     [class.Notdisabled]="!account.disabled"
                     [class.disabled]="account.disabled">
                  <span>{{ account.is_checked?'Selected': account.disabled?'Connected':'Connect' }}</span>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </label>

        <!-- Start warning message -->
        <ng-container *ngIf="account.app_install_link && account.app_install_link !== ''">
          <div class="warning_message">
            <div class="icon_main">
              <span nz-icon nzType="warning" nzTheme="outline"></span>
            </div>
            <div class="dtl_main">
              <p>Groups must have {{ sharedService.appName }} as an app on facebook.com</p>
              <a [href]="account.app_install_link" target="_blank">Add {{ sharedService.appName }} as an app</a>
            </div>
          </div>
        </ng-container>
        <!-- End warning message -->



      </div>
    </ng-container>
  </div>

  <div class="account_list" *ngIf="isAccountLoading">
    <ng-container>
      <nz-skeleton [nzAvatar]="true" [nzParagraph]="{ rows: 1 }" *ngFor="let item of dummyItems"></nz-skeleton>
    </ng-container>
  </div>

  <ng-container *ngIf="!isAccountLoading && (accounts.length===0 || (accountSearchText!=='' && filteredAccounts.length==0))">
    <app-empty [message]="'No Account Found!'"></app-empty>
  </ng-container>
</perfect-scrollbar>

<nz-modal [(nzVisible)]="showPermissionsPopup" nzTitle="Permissions" [nzFooter]="null"
          (nzOnCancel)="cancelPermission()" nzMaskClosable="false"
          [nzClassName]="'account_permissions'">
  <ng-container *nzModalContent>
    <div class="account_permissions_body">

      <h3>Permission Missing</h3>
      <ul>
        <li *ngFor="let permission of permissions['have_not_permission']">
          <b>{{permission}}</b>
        </li>
      </ul>

      <h3>Following functionalities will not work properly</h3>
      <ul>
        <li *ngFor="let permission of permissions['have_not_permission'];">
          {{ permission }}
          <br>
          <b>{{permissions['modules'][permission]}}</b>
        </li>
      </ul>
    </div>
    <div class="account_permissions_action">
      <button nz-button nzType="primary" (click)="cancelPermission()">Cancel</button>
      <button class="btn_ok" type="submit"
              nz-button nzType="primary"
              (click)="reconnectAccount()">Reconnect</button>
    </div>
  </ng-container>
</nz-modal>
