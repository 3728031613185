import { Component, Input } from "@angular/core";

import { SharedService } from "../../services/shared.service";

@Component({
    selector: 'app-provider',
    templateUrl: './app-provider.component.html',
    styleUrls: ['./app-provider.component.less']
})

export class appProviderComponent {

    @Input() provider:string = "";
    @Input() className:string = "";
    @Input() iconSize: string = this.sharedService.mediumIcon;
    
    constructor(public sharedService: SharedService) { }
}
